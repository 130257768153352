import gql from 'graphql-tag';
import { deliveryAddressFragment, basketItemFragment, getBasketFragment } from './fragments';

export const GET_BASKET = gql`
  query basket_getBasket($basketId: ID) {
    basket_getBasket(basketId: $basketId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_APPLY_SHIPPING_ADDRESS = gql`
  mutation basket_applyShippingAddress(
    $basketId: ID
    $firstName: String
    $lastName: String
    $street: String
    $city: String
    $region: String
    $postcode: String
    $countryId: String
    $phone: String
  ) {
    basket_applyShippingAddress(
      basketId: $basketId
      firstName: $firstName
      lastName: $lastName
      street: $street
      city: $city
      region: $region
      postcode: $postcode
      countryId: $countryId
      phone: $phone
    ) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_ORDER = gql`
  mutation basket_order($basketId: ID, $leadAttribution: [KeyValueInput]) {
    basket_order(basketId: $basketId, leadAttribution: $leadAttribution) {
      id
      orderNumber
      date
      type
      status
      statusLabel
      itemCount
      currency
      subTotal
      discountAmount
      discountCode
      shipping
      total
      awinParts
      awinTotal
    }
  }
`;

export const BASKET_SET_PAYMENT_METHOD = gql`
  mutation basket_setPaymentMethod($basketId: ID, $methodId: ID!) {
    basket_setPaymentMethod(basketId: $basketId, methodId: $methodId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_REMOVE_PAYMENT_INTENT = gql`
  mutation basket_removePaymentIntent($basketId: ID) {
    basket_removePaymentIntent(basketId: $basketId)
  }
`;

export const BASKET_MERGE_GUEST = gql`
  mutation basket_mergeGuestBasket($basketId: ID!) {
    basket_mergeGuestBasket(basketId: $basketId)
  }
`;

export const BASKET_CHECK_PRODUCT_RESTRICTIONS = gql`
  query basket_checkProductRestrictions($basketId: ID!) {
    basket_checkProductRestrictions(basketId: $basketId) {
      hasRestrictedItems
      restrictedItems
    }
  }
`;

export const BASKET_SET_SHIPPING_ADDRESS = gql`
  mutation basket_setShippingAddress($basketId: ID, $addressId: ID!) {
    basket_setShippingAddress(basketId: $basketId, addressId: $addressId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;
