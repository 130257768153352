import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
// services
import { getCookie } from '../../../../services/cookies';
// styles
import styles from './custom-react-select.module.scss';
import reactSelectStyles from './react-select-styles';

interface IProps {
  id: string;
  label: string;
  error?: any;
  errorMessage?: string;
  options: any;
  control: any;
  defaultValue: any;
}

const CustomReactSelect: React.FC<IProps> = ({
  id,
  label,
  error,
  errorMessage,
  control,
  options,
  defaultValue,
}) => {
  const getDefaultValue = (value: any, options: any) => {
    const realValue = value || defaultValue;
    return options.find((c: any) => c.value === realValue) || options[0];
  };

  return (
    <div className={styles.container}>
      <Controller
        name={id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            id={id}
            name={id}
            onChange={c => onChange(c.code)}
            options={options}
            defaultValue={getDefaultValue(value, options)}
            styles={reactSelectStyles}
          />
        )}
      />
      <label htmlFor={id} className={styles.placeholderActive}>
        {label}
      </label>
      {error && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
};

export default CustomReactSelect;
