export const PAYMENT_ADD_FORM_DATA = {
  inputs: [
    {
      id: 'name',
      type: 'text',
      label: 'Name on card',
      validation: {
        required: true,
      },
      errorMessage: {
        required: 'Please enter the name this card is associated with',
      },
    },
  ],
  submitLabel: 'Pay',
};
