import React from 'react';

import styles from './email-loader.module.scss';

import emailIcon from './assets/letter_Icon-01.svg';

const EmailLoader = () => {
  return (
    <div className={styles.emailLoader}>
      <img className={styles.icon} src={emailIcon} alt="email icon" />
      <p>Your magic link is being prepared…</p>
    </div>
  );
};

export default EmailLoader;
