import React, { useState, useRef } from 'react';
import { navigate } from '@reach/router';
import { IFeatureFlags } from 'interfaces/context';
import { logout } from 'services/auth';
import { getCookie } from '../../services/cookies';
import { ACCOUNT_INFO } from 'constants/account';

interface IModal {
  isOpen: boolean;
  modalContent: React.ReactElement | null;
  closeModal?: boolean;
}

export const useGlobalState = () => {
  const { urls } = ACCOUNT_INFO;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>(
    getCookie('currency')
  );
  const [showChangeCurrency, setShowChangeCurrency] = useState<boolean>(false);
  const [modal, setModal] = useState<IModal>({
    isOpen: false,
    modalContent: null,
    closeModal: false,
  });
  const [showSmsOptInModal, setShowSmsOptInModal] = useState(false);
  const isOptInToSmsAccepted = useRef(false);

  const logIn = () => {
    setIsLoggedIn(true);
    navigate('/');
  };

  const logOut = () => {
    logout();
    setIsLoggedIn(false);
    setIsLoading(true);
    navigate(urls.url_base);
  };

  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags | null>(null);

  return {
    modal,
    setModal,
    isLoggedIn,
    isLoadingPayment,
    setIsLoadingPayment,
    logIn,
    logOut,
    currencyCode,
    setCurrencyCode,
    showChangeCurrency,
    setShowChangeCurrency,
    featureFlags,
    setFeatureFlags,
    isLoading,
    setIsLoading,
    showSmsOptInModal,
    setShowSmsOptInModal,
    isOptInToSmsAccepted,
  };
};
