import React from 'react';
import Error from 'components/core/Error';
import styles from './submit-button.module.scss';

interface IProps {
  loading?: boolean;
  success?: any;
  error?: any;
  buttonLabel?: string;
  loadingLabel?: string;
  successMessage?: string;
  fullWidth?: boolean;
  onClick?: any;
  disabled?: any;
}

const SubmitButton: React.FC<IProps> = ({
  loading,
  success,
  error,
  buttonLabel = 'Submit',
  loadingLabel = 'Loading...',
  successMessage = '',
  fullWidth,
  onClick,
  disabled,
}) => {
  return (
    <div>
      {!loading && success && successMessage !== '' && (
        <p className={styles.success}>{successMessage}</p>
      )}

      {!loading && <Error data={error} />}

      {loading && (
        <button
          type={onClick ? 'button' : 'submit'}
          className={fullWidth ? styles.buttonFullWidth : styles.button}
          disabled={loading || disabled}
          onClick={onClick}
        >
          {loadingLabel} <div className={styles.loader}></div>
        </button>
      )}

      {!loading && (
        <button
          type={onClick ? 'button' : 'submit'}
          className={fullWidth ? styles.buttonFullWidth : styles.button}
          disabled={loading || disabled}
          onClick={onClick}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  );
};

export default SubmitButton;
