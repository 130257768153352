import React from 'react';
import styles from './custom-checkbox.module.scss';

interface IProps {
  id: string;
  label: any;
  name: string;
  register: any;
  disabled?: boolean;
  error?: any;
  errorMessage?: string;
}

const CustomRadio: React.FC<IProps> = ({
  id,
  label,
  name,
  register,
  disabled = false,
  error,
  errorMessage,
}) => {
  return (
    <div>
      <label
        className={!disabled ? styles.container : styles.containerDisabled}
      >
        {label}
        <input
          key={id}
          id={id}
          value={id}
          type="radio"
          name={name}
          disabled={disabled}
          {...register}
        />
        <span className={error ? styles.checkMarkError : styles.checkmark} />
      </label>
      {error && errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
};

export default CustomRadio;
