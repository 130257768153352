export interface IAddress {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  postcode: string;
  countryCode: string;
  phone: string;
  smsOptIn: boolean;
}

export interface ICountry {
  id: string;
  code: string;
  name: string;
}

export interface IUserDeliveryAddress {
  id: string;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  region: string;
  postcode: string;
  countryId: string;
}

export interface IFormatAddress {
  firstName: string;
  lastName: string;
  countryCode: string;
  street: string;
  country: string;
  city: string;
  postcode: string;
}

export interface IAddressDetailItem {
  Id: string;
  DomesticId: string;
  Language: string;
  LanguageAlternatives: string;
  Department: string;
  Company: string;
  SubBuilding: string;
  BuildingNumber: string;
  BuildingName: string;
  SecondaryStreet: string;
  Street: string;
  Block: string;
  Neighbourhood: string;
  District: string;
  City: string;
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  Line5: string;
  AdminAreaName: string;
  AdminAreaCode: string;
  Province: string;
  ProvinceName: string;
  ProvinceCode: string;
  PostalCode: string;
  CountryName: string;
  CountryIso2: string;
  CountryIso3: string;
  CountryIsoNumber: number;
  SortingNumber1: string;
  SortingNumber2: string;
  Barcode: string;
  POBoxNumber: string;
  Label: string;
  Type: string;
  DataLevel: string;
  Field1: string;
  Field2: string;
  Field3: string;
  Field4: string;
  Field5: string;
  Field6: string;
  Field7: string;
  Field8: string;
  Field9: string;
  Field10: string;
  Field11: string;
  Field12: string;
  Field13: string;
  Field14: string;
  Field15: string;
  Field16: string;
  Field17: string;
  Field18: string;
  Field19: string;
  Field20: string;
}
export interface IAddressDetail {
  Items: IAddressDetailItem[];
}

export enum ErrorTypes {
  ContainerRequired = '1001',
}

export interface IAddressResponse {
  Description: string;
  Highlight: string;
  Id: string;
  Text: string;
  Type: 'Postcode' | 'Locality' | 'Street' | 'Address' | 'BuildingNumber';
  Error?: ErrorTypes;
}
