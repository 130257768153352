import React, { useEffect } from 'react';

import DeliverySelection from './DeliverySelection';

import DeliveryAddress from './DeliveryAddress';
import DeliveryAdd from './DeliveryAdd';
import SkeletonDefault from 'components/core/skeletons/SkeletonDefault';
import { VIEWS } from '../constants';
import { useDelivery } from 'hooks/useDelivery';

interface IProps {
  view: string;
  setView: any;
}

const Delivery: React.FC<IProps> = ({ view, setView }) => {
  const {
    countries,
    loading,
    hasUserDeliveryAddress,
    basketDeliveryAddress,
    onComplete,
  } = useDelivery(setView);

  if (loading) return <SkeletonDefault />;
  if (view === VIEWS.delivery.selection)
    return (
      <DeliverySelection
        onCancel={() => setView(VIEWS.delivery.default)}
        onAddNewAddress={() => setView(VIEWS.delivery.add)}
        {...{ countries, onComplete }}
      />
    );
  if (view === VIEWS.delivery.add)
    return (
      <DeliveryAdd
        onCancel={() => setView(VIEWS.delivery.default)}
        {...{ countries, onComplete }}
      />
    );

  return (
    <DeliveryAddress
      onChange={() => {
        hasUserDeliveryAddress
          ? setView(VIEWS.delivery.selection)
          : setView(VIEWS.delivery.add);
      }}
      {...{ address: basketDeliveryAddress, countries }}
    />
  );
};

export default Delivery;
