import React from 'react';
import styles from './custom-input.module.scss';

interface IProps {
  id: string;
  type?: string;
  label: string;
  register?: any;
  error?: any;
  errorMessage?: string;
  hasValue?: boolean;
  disabled?: boolean;
  autocomplete?: string;
  onInput?: (value: any) => void;
  customClass?: string;
}

const CustomInput: React.FC<IProps> = ({
  id,
  type = 'text',
  label,
  register,
  error,
  errorMessage,
  hasValue,
  disabled = false,
  autocomplete = 'on',
  onInput,
  customClass = '',
}) => (
  <div className={styles.inputWrapper}>
    <div
      className={
        !error
          ? customClass
            ? styles[customClass + 'Container']
            : styles.container
          : customClass
          ? styles[customClass + 'ErrorContainer']
          : styles.containerError
      }
    >
      <input
        id={id}
        name={id}
        type={type}
        disabled={disabled}
        autoComplete={autocomplete}
        onInput={onInput ? e => onInput((e.target as HTMLInputElement).value) : undefined}
        {...register}
      />
      <label htmlFor={id} className={!hasValue ? styles.placeholder : styles.placeholderActive}>
        {label}
      </label>
    </div>
    {error && <small className={customClass ? styles[customClass + 'Error'] : styles.error}>{errorMessage}</small>}
  </div>
);

export default CustomInput;
