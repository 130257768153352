import React from 'react';
import { getCountryNameById } from 'services/global';

import styles from './address.module.scss';
interface IProps {
  address: any;
  countries: any;
  customClass?: string;
}

const Address: React.FC<IProps> = ({
  address,
  countries,
  customClass = null,
}) => {
  const { firstName, lastName, street, city, postcode, countryId } =
    address || {};
  const countryName = countries && getCountryNameById(countries, countryId);
  const isAddress = street && city;

  return (
    <div className={customClass || styles.address}>
      {isAddress ? (
        <>
          <strong>
            {(firstName || '') + (lastName ? ' ' + lastName : '') + ', '}
          </strong>
          <span>{`${street}, ${city}, ${postcode}, ${countryName}`}</span>
        </>
      ) : (
        <span>No delivery address found</span>
      )}
    </div>
  );
};

export default Address;
