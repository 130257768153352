import React, { useState } from 'react';
import { IBasketOrderDetails } from 'interfaces/basket';
import { formatPrice } from 'services/format';
import Button from 'components/core/Button';
import useRestrictedItems from 'hooks/useRestrictedItems';
import styles from './basket-order-details.module.scss';

const BasketOrderDetails: React.FC<IBasketOrderDetails> = ({ items, price, hasDeliveryAddress }) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const { restricted, country } = useRestrictedItems();

  const itemsSubset = showAllItems ? items : items?.slice(0, 3);

  return (
    <section className={styles.basketOrderDetails}>
      <ul className={styles.basketOrderDetails__items}>
        {itemsSubset?.map((item: any) => (
          <li key={item.id} className={styles.basketOrderDetails__item}>
            <div className={styles.item__element}>
              <span>{item.name}</span>
              <span> {formatPrice(item.rowTotalWithDiscount, price?.currency)}</span>
            </div>
            {restricted && restricted.includes(item.id) && (
              <div className={styles.warning}>This item is unavailable for delivery to {country}</div>
            )}
            {item.qty > 1 ? (
              <div className={styles.item__subElement}>
                <span>{`Qty ${item.qty}`}</span>
                <span>{`${formatPrice(item.itemPrice, price?.currency)} each`}</span>
              </div>
            ) : null}
            {item.frequencyDays ? (
              <div className={styles.item__subElement}>
                <>{`${price && formatPrice(item.rowTotal, price.currency)}`}</>
                <>{` every ${item.frequencyDays} days`}</>
              </div>
            ) : null}
          </li>
        ))}
        {items && items?.length > 3 ? (
          <Button
            type="link"
            onClick={() => setShowAllItems(!showAllItems)}
            label={
              showAllItems ? (
                <>
                  <span>Show less</span>
                  <span className={styles.arrowUp}></span>
                </>
              ) : (
                <>
                  <span>{`Show all ${items.length} items`}</span>
                  <span className={styles.arrowDown}></span>
                </>
              )
            }
          ></Button>
        ) : null}
      </ul>
      <ul className={styles.basketOrderDetails__subtotal}>
        {/* <li className={styles.basketOrderDetails__item}>
          <div className={styles.item__element}>
            <span>Subtotal</span>
            <span>{formatPrice(price.subtotal, price.currency)}</span>
          </div>
        </li> */}
      </ul>
      <ul className={styles.basketOrderDetails__shipping}>
        <li className={styles.basketOrderDetails__item}>
          <div className={styles.item__element}>
            <span>Shipping</span>
            <span>
              {hasDeliveryAddress
                ? price?.shipping === 0
                  ? 'Free'
                  : formatPrice(price?.shipping, price?.currency)
                : price?.shipping === 0 && (price.totalWithCreditAllocated && price.totalWithCreditAllocated > 20)
                ? 'Free delivery to UK'
                : 'Calculated at next step'}
            </span>
          </div>
        </li>
        {price && price.discount > 0 && (
          <li className={styles.basketOrderDetails__item}>
            <div className={styles.item__element}>
              <span>Discount</span>
              <span>-{formatPrice(price.discount, price.currency)}</span>
            </div>
          </li>
        )}
        {price && price.creditAllocated > 0 && (
          <li className={styles.basketOrderDetails__item}>
            <div className={styles.item__element}>
              <span>Credit</span>
              <span>-{formatPrice(price.creditAllocated, price.currency)}</span>
            </div>
          </li>
        )}
      </ul>
      <ul className={styles.basketOrderDetails__total}>
        <li className={styles.basketOrderDetails__item}>
          <div className={styles.item__element}>
            <span>Amount due</span>
            <span>{formatPrice(price?.totalWithCreditAllocated, price?.currency)}</span>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default BasketOrderDetails;
