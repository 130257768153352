import gql from 'graphql-tag';
import { paymentMethodFragment } from './fragments';

export const PAYMENT_METHODS = gql`
  query user_paymentMethods {
    user_paymentMethods {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`;

export const CREATE_PAYMENT_SETUP_INTENT = gql`
  mutation user_createPaymentSetupIntent(
    $token: String!
    $setAsDefault: Boolean
  ) {
    user_createPaymentSetupIntent(token: $token, setAsDefault: $setAsDefault) {
      clientSecret
    }
  }
`;

export const USER_STRIPE_BALANCE = gql`
  query user_stripeBalance {
    user_stripeBalance
  }
`;
