import gql from 'graphql-tag';

export const basketPaymentFragment = gql`
  fragment payment on Basket {
    payment {
      id
      type
      cardIssuer
      name
      lastFourDigits
      expiryMonth
      expiryYear
      postcode
      default
    }
  }
`;

export const basketItemFragment = gql`
  fragment basketItem on BasketItem {
    id
    sku
    name
    productName
    planName
    qty
    allowQuantityChange
    image
    itemPrice
    itemPriceWithDiscount
    rowTotal
    rowTotalWithDiscount
    frequencyDays
    upsellSku
    upsellSavePercentage
    offersApplied {
      offerId
      offerType
      offerDescription
      discount
      offerRedemptions
      offerDurationType
    }
  }
`;

export const deliveryAddressFragment = gql`
  fragment deliveryAddress on Basket {
    deliveryAddress {
      id
      firstName
      lastName
      street
      city
      region
      postcode
      countryId
      phone
    }
  }
`;

export const getBasketFragment = gql`
  fragment getBasket on Basket {
    basketId
    itemCount
    currency
    subTotal
    discountAmount
    discountCode
    balanceAmount
    balanceUsed
    totalWithBalance
    deliveryAddressRequired
    ...deliveryAddress
    deliveryMethod
    shippingMethod
    shipping
    creditAvailable
    creditAllocated
    totalWithCreditAllocated
    ...payment
    lastModifiedItem {
      ...basketItem
    }
    items {
      ...basketItem
    }
  }
  ${deliveryAddressFragment}
  ${basketPaymentFragment}
  ${basketItemFragment}
`;

export const paymentMethodFragment = gql`
  fragment paymentMethod on PaymentMethod {
    id
    name
    type
    cardIssuer
    lastFourDigits
    expiryMonth
    expiryYear
    postcode
    default
  }
`;

export const userDetailsFragment = gql`
  fragment userDetails on UserDetails {
    firstName
    lastName
    email
    phone
    dob
    customerDataPlatformId
  }
`;
