import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobalContext } from 'context/global/global-context';

import styles from './modal-provider.module.scss';

const ModalProvider: React.FC = ({ children }) => {
  Modal.setAppElement('#root');

  const [modalStyle, setModalStyle] = useState(styles.modal);
  const {
    modal: { isOpen, modalContent, closeModal },
    setModal,
  } = useGlobalContext();

  const submitCloseModal = () => {
    setModalStyle(styles.modal);
    setTimeout(() => {
      setModal({ isOpen: false, modalContent: null });
    }, 500);
  };

  const afterOpenModal = () => {
    setModalStyle(`${styles.modal} ${styles.afterOpenModal}`);
  };

  useEffect(() => {
    if (closeModal) submitCloseModal();
  }, [closeModal]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={submitCloseModal}
        className={modalStyle}
        overlayClassName={styles.overlay}
        onAfterOpen={afterOpenModal}
      >
        {modalContent}
      </Modal>
      {children}
    </>
  );
};

export default ModalProvider;
