import React from 'react';
// constants
import { ACCOUNT_INFO } from 'constants/account';
// styles
import styles from './footer.module.scss';
// services
import { formatCurrency } from '../../../../services/format';
// context
import { useGlobalContext } from '../../../../context/global/global-context';
// components
import CurrencyModalContent from '../../../feature/ChangeCurrency';

const Layout: React.FC = () => {
  const { urls } = ACCOUNT_INFO;
  const { setModal, currencyCode } = useGlobalContext();
  const currency = currencyCode && formatCurrency(currencyCode);

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        {/* <div className={styles.footer__poweredBy}>
          <span>Powered by </span>
          <a href={urls.url_base} target="_blank" rel="noreferrer">
            <img src={logo} alt="logo"></img>
          </a>
        </div> */}
        <div className={styles.links}>
          {/*Temporary hiding this, until get BE support*/}
          {/*<a*/}
          {/*  className={styles.change__currency}*/}
          {/*  onClick={() =>*/}
          {/*    setModal({ isOpen: true, modalContent: <CurrencyModalContent /> })*/}
          {/*  }*/}
          {/*>*/}
          {/*  Change currency ({currency})*/}
          {/*</a>*/}
          <>
            <a
              className={styles.link}
              href={urls.url_terms}
              target="_blank"
              rel="noreferrer"
            >
              Terms
            </a>
            <a
              className={styles.link}
              href={urls.url_privacy}
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
          </>
        </div>
      </div>
    </footer>
  );
};

export default Layout;
