import * as rudderanalytics from 'rudder-sdk-js';
import { setCookie } from './cookies';
import { COOKIE_MAX_AGE } from 'constants/cookies';
import { identifyHeap } from './tracking/heap';

interface IUtmParamObject {
  key: string;
  value: string;
}

interface IUtmParamObject {
  key: string;
  value: string;
}

interface IUtmParamObject {
  key: string;
  value: string;
}

var rudderstackEnabled = true;

export const isSSR = typeof window === 'undefined';

export const initialiseRudderAnalytics = () => {
  const WRITE_KEY = process.env.REACT_APP_RUDDERSTACK_PROD_WRITE_KEY!;
  const DATA_PLANE_URL = process.env.REACT_APP_RUDDERSTACK_SERVER_URL!;

  if (!WRITE_KEY || !DATA_PLANE_URL) {
    console.warn('Rudderstack disabled: keys not found');
    rudderstackEnabled = false;
    return;
  }

  rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL);
};

export const trackIdentify = (id: any, options?: any) => {
  if (!isSSR && rudderanalytics && rudderstackEnabled) {
    rudderanalytics.identify(id, options);
  }
  identifyHeap(id);
};

export const trackEvent = (name: string, options?: any) => {
  !isSSR && rudderanalytics && rudderstackEnabled && rudderanalytics.track(name, options);
};

export const checkUtmParams = () => {
  var urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      setCookie(key, value, COOKIE_MAX_AGE);
    }
  });
};

export const getUtmParamsMap = () => {
  let params: IUtmParamObject[] = [];
  document.cookie
    .split(';')
    .filter(cookie => cookie.includes('utm_'))
    .forEach(trackingCookie => {
      const [name, value] = trackingCookie.trim().split('=');
      params.push({
        key: name,
        value: value,
      });
    });

  return params;
};

export const getUtmParamsAsUrlQueryParams = () => {
  const utmParamsMap = getUtmParamsMap();
  if (!utmParamsMap.length) {
    return {};
  }
  return utmParamsMap.reduce((utmQueryParamsObject, { key, value }) => {
    return {
      ...utmQueryParamsObject,
      [key]: value,
    };
  }, {});
};
