import React, { useEffect } from 'react';
import DefaultForm from 'components/form/DefaultForm';
import DeliveryAddress from 'components/feature/Address';
import { DELIVERY_FORM } from './data';
import TitleButton from 'components/core/TitleButton';

import styles from './delivery-selection.module.scss';
import { useDeliverySelection } from 'hooks/useDelivery';
import { SkeletonForm } from 'components/core/skeletons';
import { joinParamsWithUnderscore } from 'services/format';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

interface IProps {
  countries: any;
  onComplete: any;
  onAddNewAddress: any;
  onCancel: any;
}

const DeliverySelection: React.FC<IProps> = ({
  countries,
  onComplete,
  onAddNewAddress,
  onCancel,
}) => {
  const {
    data,
    error,
    loading,
    userAddresses,
    loadingUserAddresses,
    selectedAddressId,
    selectDeliveryAddress,
  } = useDeliverySelection(onComplete);

  useEffect(() => {
    trackGAEvent('checkout_address');
    trackHeap('checkout_address');
  }, []);

  const inputs = userAddresses?.map((address: any) => {
    return {
      id: joinParamsWithUnderscore([address.id, address.street]), //Workaround to be updated. More info at FE-173
      type: 'radio',
      label: (
        <DeliveryAddress
          {...{ address, countries, customClass: styles.deliveryAddress }}
        />
      ),
      name: 'addressId',
      validation: { required: true },
    };
  });

  const formInfo = {
    ...DELIVERY_FORM,
    inputs,
    setValues: [
      {
        name: 'addressId',
        value: selectedAddressId,
      },
    ],
    errorMessage: {
      required: 'Please select an address',
    },
  };

  if (loadingUserAddresses) return <SkeletonForm />;

  return (
    <>
      <TitleButton
        title="Choose your delivery address"
        buttonLabel="Close"
        onClickButton={onCancel}
      />
      <DefaultForm
        {...{
          formInfo,
          onSubmit: selectDeliveryAddress,
          onCancel: onAddNewAddress,
          data,
          loading,
          error,
          overflow: true,
          className: styles.deliverySelection,
        }}
      />
    </>
  );
};

export default DeliverySelection;
