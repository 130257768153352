export const ADDRESS_FIND_INFO = {
  inputs: [
    [
      {
        id: 'firstName',
        label: 'First name',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter your first name',
        },
      },
      {
        id: 'lastName',
        label: 'Last name',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter your last name',
        },
      },
    ],
  ],
  cancelLabel: 'Enter address manually',
  textBetweenLabels: 'or',
};
