import React, { useEffect } from 'react';
import TitleButton from 'components/core/TitleButton';
import AddressFind from './AddressFind';
import AddressManual from './AddressManual';
import { SkeletonForm } from 'components/core/skeletons';
import { useDeliveryAdd } from 'hooks/useDelivery';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

interface IProps {
  countries: any;
  onComplete: any;
  onCancel: any;
}

const DeliveryAdd: React.FC<IProps> = ({ countries, onComplete, onCancel }) => {
  const {
    userFirstName,
    userLastName,
    setUserFirstName,
    setUserLastName,
    isManualAddress,
    setIsManualAddress,
    addressData,
    handleSelectAddress,
    handleAddManualDeliveryAddressSubmit,
    loadingAddAddress,
    loadingUserDetails,
    errorAddAddress,
  } = useDeliveryAdd(onComplete);

  useEffect(() => {
    trackGAEvent('checkout_address');
    trackHeap('checkout_address');
  }, []);

  if (loadingUserDetails) return <SkeletonForm />;

  return (
    <>
      <TitleButton title="Who is this being delivered to?" buttonLabel="Cancel" onClickButton={onCancel} />
      {!isManualAddress ? (
        <div>
          <AddressFind
            selectAddress={handleSelectAddress}
            firstName={userFirstName}
            lastName={userLastName}
            setFirstName={setUserFirstName}
            setLastName={setUserLastName}
            onCancel={() => setIsManualAddress(true)}
          />
        </div>
      ) : (
        <AddressManual
          firstName={userFirstName}
          lastName={userLastName}
          addressData={addressData}
          countries={countries}
          loading={loadingAddAddress}
          error={errorAddAddress}
          submit={handleAddManualDeliveryAddressSubmit}
        />
      )}
    </>
  );
};

export default DeliveryAdd;
