import React, { Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; //reach-router
import { ApolloProvider } from '@apollo/client';
import client from 'apollo';

import { AppPage, LetmeinPage } from 'pages';
import { GlobalContextProvider } from 'context/global/global-context';
import { SkeletonProvider, ModalProvider, StripeProvider } from 'utils/providers';
import { checkUtmParams, initialiseRudderAnalytics } from 'services/tracking';
import { initSentry } from 'services/sentry';
import { initGTM } from 'services/gtm';

import 'vitl-component-library/dist/index.css';

const IndexRouter: React.FC = () => {
  useEffect(() => {
    initGTM();
    initSentry();
    initialiseRudderAnalytics();
    checkUtmParams();
  }, []);

  return (
    <ApolloProvider client={client}>
      <Sentry.ErrorBoundary>
        <GlobalContextProvider>
          <ModalProvider>
            <SkeletonProvider>
              <StripeProvider>
                <Suspense fallback={<p>Loading...</p>}>
                  <BrowserRouter>
                    <Routes>
                      <Route path="*" element={<AppPage />} />
                      <Route path="/letmein" element={<LetmeinPage />} />
                    </Routes>
                  </BrowserRouter>
                </Suspense>
              </StripeProvider>
            </SkeletonProvider>
          </ModalProvider>
        </GlobalContextProvider>
      </Sentry.ErrorBoundary>
    </ApolloProvider>
  );
};

export default IndexRouter;
