import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonBasket = () => {
  return (
    <>
      <Skeleton
        circle={true}
        height={30}
        width={30}
        style={{ marginBottom: 32 }}
      />
      <Skeleton width={`100%`} />
      <Skeleton width={`50%`} style={{ marginBottom: 32 }} />
      <Skeleton height={`300px`} />
    </>
  );
};
export default SkeletonBasket;
