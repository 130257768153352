import React, { useState, useEffect } from 'react';
import SignInForm from './SignInForm';
import ContinueAsGuestForm from './ContinueAsGuestForm';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

const SignIn: React.FC = () => {
  const [isGuest, setIsGuest] = useState<boolean>(false);

  useEffect(() => {
    trackGAEvent('checkout_login_view');
    trackHeap('checkout_login_view');
  }, []);

  const continueAsGuest = () => setIsGuest(true);
  const cancelGuest = () => setIsGuest(false);

  if (isGuest) return <ContinueAsGuestForm {...{ onCancel: cancelGuest }} />;
  return <SignInForm {...{ onCancel: continueAsGuest }} />;
};

export default SignIn;
