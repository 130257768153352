import React from 'react';

export const useClickOutside = (ref: React.RefObject<HTMLInputElement>, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', event => handleClick(event));
    return () => {
      document.removeEventListener('click', () => handleClick);
    };
  });
};
