import React, { useState, useEffect } from 'react';
import CardDetails from 'components/feature/CardDetails';
import TitleButton from 'components/core/TitleButton';
import SubmitButton from 'components/form/elements/SubmitButton';

// constants
import { usePaymentPay } from 'hooks/usePayment';
import { useBasket } from 'hooks/useBasket';
import { useGlobalContext } from 'context/global/global-context';
import useRestrictedItems from 'hooks/useRestrictedItems';
import SkeletonDefault from '../../../../components/core/skeletons/SkeletonDefault';
import { ApolloError } from 'apollo-client';

interface IProps {
  onChange: any;
  onComplete: any;
  onError?: (error: ApolloError) => void;
}

const PaymentPay: React.FC<IProps> = ({ onChange, onComplete }) => {
  const { basket, basketPayment, basketDeliveryAddress, loading: loadingBasket } = useBasket();
  const { isLoadingPayment } = useGlobalContext();
  const { restricted, loading: loadingProductRestrictions } = useRestrictedItems();
  const [error, setError] = useState<ApolloError | null>(null);

  const {
    data,
    error: errorPayment,
    paymentLabel,
    loading: loadingPayment,
    orderBasket,
  } = usePaymentPay(onComplete, err => setError(err));

  const loading = isLoadingPayment || loadingPayment || loadingBasket || loadingProductRestrictions;

  return (
    <>
      <TitleButton
        title="Payment"
        buttonLabel="Change"
        disabled={isLoadingPayment}
        onClickButton={onChange}
      />
      <CardDetails
        loading={loadingBasket || loadingProductRestrictions}
        cardIssuer={basketPayment?.cardIssuer}
        lastFourDigits={basketPayment?.lastFourDigits}
      />
      {basketPayment?.cardIssuer && (basketDeliveryAddress || !basket?.deliveryAddressRequired) && (
        <>
          <SubmitButton
            loading={loading}
            success={data}
            error={error || errorPayment}
            disabled={restricted}
            buttonLabel={paymentLabel}
            loadingLabel="Processing..."
            onClick={orderBasket}
            fullWidth
          />
        </>
      )}
    </>
  );
};

export default PaymentPay;
