import React, { useEffect } from 'react';

import BackIcon from 'assets/img/back-icon.svg';
import emailIconDone from './assets/letter_Icon-01-done.svg';

import styles from './magic-link-view.module.scss';
interface IProps {
  message?: string;
  email?: string;
  changeEmail: (email: string | undefined) => void;
}

const TOP_LEVEL_EMAIL_PROVIDERS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
];

const MagicLinkView = ({ message, email, changeEmail }: IProps) => {
  const [topLevelProvider, setTopLevelProvider] = React.useState<string>('');

  useEffect(() => {
    const match = TOP_LEVEL_EMAIL_PROVIDERS.find(emailProvider =>
      email?.includes(emailProvider)
    );
    setTopLevelProvider(!!match ? match : '');
  }, [email]);

  const handleOpenEmail = () => {
    switch (topLevelProvider) {
      case 'gmail.com':
        window.open(`https://mail.google.com/mail/u/0/#inbox`, '_blank');
        break;
      case 'yahoo.com':
        window.open(`https://mail.yahoo.com/mail/u/0`, '_blank');
        break;
      case 'hotmail.com':
        window.open(`https://outlook.live.com/mail/u/0`, '_blank');
        break;
      case 'outlook.com':
        window.open(`https://outlook.live.com/mail/u/0`, '_blank');
        break;
    }
  };

  return (
    <div className={styles.magicLinkSent}>
      <img src={emailIconDone} alt="email icon" className={styles.emailIcon} />
      {message && (
        <p className={styles.success}>
          {message} {email}
        </p>
      )}
      <div className={styles.options}>
        <p onClick={() => changeEmail(email)} className={styles.button}>
          <img className={styles.backIcon} src={BackIcon} alt="back"></img>
          Back
        </p>
        {topLevelProvider && (
          <p onClick={handleOpenEmail} className={styles.button}>
            Open {topLevelProvider}
          </p>
        )}
      </div>
    </div>
  );
};

export default MagicLinkView;
