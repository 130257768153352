import React from 'react';
import Address from 'components/feature/Address';
import TitleButton from 'components/core/TitleButton';
import { useGlobalContext } from 'context/global/global-context';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

interface IProps {
  address: any;
  countries: any;
  onChange: any;
}

const DeliveryAddress: React.FC<IProps> = ({
  address,
  countries,
  onChange,
}) => {
  const { isLoadingPayment } = useGlobalContext();

  const onChangeHandler = () => {
    trackGAEvent('checkout_address_change');
    trackHeap('checkout_address_change');
    onChange();
  };

  return (
    <>
      <TitleButton
        title="Delivery address"
        buttonLabel="Change"
        onClickButton={onChangeHandler}
        disabled={isLoadingPayment}
      />
      <Address {...{ countries, address }} />
    </>
  );
};

export default DeliveryAddress;
