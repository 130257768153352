import { IBasketItem } from 'interfaces/basket';
import { isSSR } from '../tracking';

// This module will need to get extracted in separate repo, as this logic is being duplicated in multiple repos
export const trackGAEvent = (name: string, data: any = {}, groups?: string | string[]) => {
  if (!isSSR && window.gtag) {
    const eventName = getGAEventNameAndCategory(name);
    const mappedData = getMappedDataForGAEvent(eventName, data);
    window.gtag('event', eventName, {
      send_to: groups || 'analytics', // analytics will send events to all ua and ga4 configs in gtag
      ...mappedData,
    });
  }
};

const getGAEventNameAndCategory = (name: string) => {
  switch (name) {
    case 'AddToCart':
      return 'add_to_cart';
    case 'RemoveFromCart':
      return 'remove_from_cart';
    case 'Social Share':
      // what to do with Click CTA event
      return 'share';
    case 'Checkout Started':
      return 'begin_checkout';
    case 'Product List Viewed':
      return 'view_item_list';
    case 'Purchase':
    case 'CompletePayment':
    case 'Checkout':
      return 'purchase';
    case 'Product Viewed':
      return 'view_item';
    case 'Search Button Clicked':
      return 'search';
    case 'Basket Viewed':
      return 'view_cart';
    case 'Finished consultation':
      return 'finished_consultation';
    case 'Started consultation':
      return 'started_consultation';
    case 'Closed consultation':
      return 'closed_consultation';
    default:
      return name;
  }
};

const getMappedDataForGAEvent = (name: string, data: any) => {
  switch (name) {
    case 'add_to_cart':
    case 'FE AddToCart':
    case 'remove_from_cart':
      return {
        currency: data.currency,
        value: data.value,
        items: mapCartItemsForGAAddOrRemoveEvent(data.items),
      };
    case 'share':
      return {
        method: data.social_media,
        item_id: data.content_name,
      };
    case 'begin_checkout':
      return {
        currency: data.currency,
        value: data.value,
        coupon: data.coupon || '',
        items: mapCartItemsForGAPurchaseEvent(data.items),
      };
    case 'purchase':
    case 'FE Order Completed':
      return {
        currency: data.currency,
        value: data.value,
        transaction_id: data.order_id || '',
        coupon: data.coupon || '',
        shipping: data.shipping || 0,
        items: mapCartItemsForGAPurchaseEvent(data.items),
      };
    default:
      return data;
  }
};

const mapCartItemsForGAAddOrRemoveEvent = (items: IBasketItem[]) =>
  items.map(item => {
    const { sku, name, qty, itemPrice, rowTotalWithDiscount } = item;
    const discount =
      itemPrice < rowTotalWithDiscount
        ? 0
        : itemPrice - rowTotalWithDiscount;
    return {
      item_id: sku,
      item_name: name,
      quantity: qty,
      price: getPriceForAddOrRemoveItem(item),
      discount,
    };
  });

const mapCartItemsForGAPurchaseEvent = (items: IBasketItem[]) =>
  items.map(item => ({
    item_id: item.sku,
    item_name: item.name,
    quantity: item.qty,
    price: item.rowTotalWithDiscount,
    discount: item.rowTotal - item.rowTotalWithDiscount,
  }));

export const getPriceForAddOrRemoveItem = (item: IBasketItem | null) => {
  if (!item) {
    return 0;
  }
  const { itemPrice, rowTotalWithDiscount, qty } = item;
  if (qty > 1) {
    // when removing items with quantity > 1
    return rowTotalWithDiscount;
  }
  return itemPrice > rowTotalWithDiscount
    ? rowTotalWithDiscount
    : itemPrice;
};
