import React from 'react';
import { IAddress, ICountry } from 'interfaces/address';
import DefaultForm from 'components/form/DefaultForm';
import { ADDRESS_MANUAL_DATA } from './data';
import { getCookie } from '../../../../../services/cookies';

interface IProps {
  firstName?: string;
  lastName?: string;
  countries: Array<ICountry>;
  addressData: any;
  loading: boolean;
  error: any;
  submit: (formData: IAddress) => void;
}

const AddressManual: React.FC<IProps> = ({
  firstName,
  lastName,
  countries,
  addressData = {},
  loading,
  error,
  submit,
}) => {
  const formInfo = {
    ...ADDRESS_MANUAL_DATA,
    inputs: [
      ...ADDRESS_MANUAL_DATA.inputs,
      {
        id: 'countryCode',
        type: 'select',
        label: 'Country',
        validation: { required: true },
        errorMessage: {
          required: `Please choose your country`,
        },
        options: countries,
      },
      {
        id: 'phone',
        type: 'phone',
        label: 'Mobile phone number (optional)',
        validation: { required: false },
        autoComplete: 'off',
        countryCallingCodeEditable: false,
        note: 'Used to update you on your order & shipping.',
      },
      {
        id: 'smsOptIn',
        type: 'checkbox',
        name: 'smsOptIn',
        label: "Yes, I'd love to receive special discounts & updates by signing up for SMS messaging.",
        validation: { required: false },
      },
    ],
    defaultValues: addressData
      ? Object.entries(addressData).reduce(
          (defaultValuesObject, [key, value]) => {
            const defaultValueKey = key !== 'countryId' ? key : 'countryCode';
            return {
              ...defaultValuesObject,
              [defaultValueKey]: value,
            };
          },
          {
            firstName,
            lastName,
            countryCode: getCookie('userCountry'),
            smsOptIn: false,
          }
        )
      : {
          firstName,
          lastName,
          countryCode: getCookie('userCountry'),
          smsOptIn: false,
        },
  };

  const onSubmit = (formData: any) => submit(formData);

  return (
    <DefaultForm
      {...{
        formInfo,
        onSubmit,
        loading,
        error,
      }}
    />
  );
};

export default AddressManual;
