import React from 'react';
import { formatPrice } from 'services/format';
import { Icon } from 'vitl-component-library';

import styles from './basket-summary.module.scss';
import { IBasketSummary } from 'interfaces/basket';
import BasketHeader from './BasketHeader';
import BasketButtonViewDetails from './BasketButtonViewDetails/BasketButonViewDetails';
import BasketOrderDetails from './BasketOrderDetails';

const BasketSummary: React.FC<IBasketSummary> = ({ logo, displayName, urls, items, price, hasDeliveryAddress }) => {
  return (
    <div className={styles.basketSummary}>
      <div>
        {price && <BasketHeader {...{ logo, urls, items, price, hasDeliveryAddress }} />}
        <div className={styles.basketSummary__container}>
          <div className={styles.productSummary}>
            <span className={styles.productSummary__name}>
              Pay {displayName}
              <span className={styles.securely} style={{ display: 'none' }}>
                securely <Icon icon="lock" size={16} color="rgba(26, 26, 26, 0.5)" />
              </span>
            </span>
            <span className={styles.productSummary__totalAmount}>{formatPrice(price.totalWithCreditAllocated, price.currency)}</span>
            <BasketButtonViewDetails {...{ items, price, hasDeliveryAddress }} />
          </div>
          <div className={styles.orderDetails}>
            <BasketOrderDetails {...{ items, price, hasDeliveryAddress }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketSummary;
