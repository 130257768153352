import { useLazyQuery } from '@apollo/client';
//queries
import { FEATURE_FLAGS } from 'queries/feature-flags';
//context
import { useGlobalContext } from 'context/global/global-context';

export const useFeatureFlags = () => {
  const { setFeatureFlags } = useGlobalContext();
  const [getFeatureFlags, { loading: loadingFeatureFlags }] = useLazyQuery(
    FEATURE_FLAGS,
    {
      onCompleted(data) {
        const featureFlags = data.config_featureFlags;

        setFeatureFlags({
          apple: featureFlags.includes('apple_pay'),
          google: featureFlags.includes('google_pay'),
        });
      },
    }
  );
  return { getFeatureFlags, loadingFeatureFlags };
};
