import React, { useState } from 'react';
import DefaultForm from 'components/form/DefaultForm';
import {
  ErrorTypes,
  IAddressDetail,
  IAddressDetailItem,
  IAddressResponse,
  IFormatAddress,
} from 'interfaces/address';

import { ADDRESS_FIND_INFO } from './data';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

interface IProps {
  selectAddress: (address: IFormatAddress) => void;
  firstName?: string;
  lastName?: string;
  setFirstName?: (value: string) => void;
  setLastName?: (value: string) => void;
  onCancel: any;
}

const AddressFind: React.FC<IProps> = ({
  selectAddress,
  firstName = '',
  lastName = '',
  setFirstName = () => undefined,
  setLastName = () => undefined,
  onCancel,
}) => {
  const [foundAddresses, setFoundAddresses] = useState<IAddressResponse[]>([]);
  const [addressLookupMessage, setAddressLookupMessage] = useState('');

  const handleCancel = () => {
    onCancel();
    trackGAEvent('checkout_address_manual');
    trackHeap('checkout_address_manual');
  }

  const getAddressDetails = (id: string) => {
    const params = new URLSearchParams({
      Key: `${process.env.REACT_APP_ADDRESS_API_KEY}`,
      Id: id,
    });
    fetch(`${process.env.REACT_APP_ADDRESS_RETRIEVE_URL}` + params.toString())
      .then(res => res.json())
      .then((detail: IAddressDetail) => detail.Items[0])
      .then((item: IAddressDetailItem) => {
        const formatStreet = () => {
          const { Line1, Line2, Line3, Line4, Line5 } = item;
          const linesOfAddress = [Line1, Line2, Line3, Line4, Line5];
          const formattedLinesOfAddress = linesOfAddress.filter(
            (line: string) => line !== ''
          );
          return formattedLinesOfAddress.join(', ');
        };

        const formatAddress: IFormatAddress = {
          city: item.City,
          postcode: item.PostalCode,
          country: item.CountryName,
          street: formatStreet(),
          countryCode: item.CountryIso2,
          firstName,
          lastName,
        };
        return formatAddress;
      })
      .then(address => selectAddress(address));
  };

  const handleInput = (name: string, value: string) => {
    switch (name) {
      case 'address':
        handleAddressInput(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
        break;
    }
  };

  const handleAddressInput = (value: string, container?: string) => {
    setAddressLookupMessage('');
    const params = new URLSearchParams({
      Key: `${process.env.REACT_APP_ADDRESS_API_KEY}`,
      Text: value,
      Container: container ? container : '',
    });
    fetch(`${process.env.REACT_APP_ADDRESS_FIND_URL}` + params.toString())
      .then(res => res.json())
      .then(({ Items }: { Items: IAddressResponse[] }) => Items)
      .then(addresses => {
        if (addresses[0].Error === ErrorTypes.ContainerRequired) {
          setFoundAddresses([]);
          return;
        }
        if (addresses.length === 0 && value !== '') {
          setFoundAddresses([]);
          setAddressLookupMessage('Please try a different address');
          return;
        }
        setFoundAddresses(addresses);
      })
      .catch(error => {
        // setErrorModalMessage(ERROR_MESSAGES.addressLookup);
        setFoundAddresses([]);
      });
  };

  const filterAddressesById = (value: string, type: string) => {
    if (type === 'Postcode' || type === 'Street' || type === 'Locality') {
      handleAddressInput('', value);
    } else if (type === 'Address') {
      getAddressDetails(value);
    } else {
      handleAddressInput(value, value);
    }
  };

  const onClickAddress = (e: any) => {
    filterAddressesById(e.Id, e.Type);
  };

  const formInfo = {
    ...ADDRESS_FIND_INFO,
    defaultValues: { firstName, lastName },
    setValues: [
      { name: 'firstName', value: firstName },
      { name: 'lastName', value: lastName },
    ],
    inputs: [
      ...ADDRESS_FIND_INFO.inputs,
      {
        id: 'address',
        type: 'address',
        label: 'Address',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter your address or postcode',
        },
        addressLookupMessage,
        foundAddresses,
        onClickAddress,
      },
    ],
  };

  return (
    <DefaultForm
      {...{
        formInfo,
        onInput: handleInput,
        onCancel: handleCancel,
      }}
    />
  );
};

export default AddressFind;
