import * as Sentry from '@sentry/react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
import { getToken, getCurrency } from 'services/cookies';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APP_SYNC_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  const currency = getCurrency();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-api-key': process.env.REACT_APP_APP_SYNC_KEY,
      authorization: token ? `Bearer ${token}` : '',
      'x-store-code': currency,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      const { message, locations, path } = error;
      Sentry.captureException(
        new Error(
          `[GraphQL error]: Path: ${path}, Message: ${message}, Location: ${locations}`
        ),
        {
          extra: {
            message,
            locations,
            path,
          },
        }
      );
    });

  if (networkError) {
    Sentry.captureException(networkError);
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const client = new ApolloClient({
  uri: process.env.REACT_APP_APP_SYNC_URL,
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Address: {
        // Combine the fields that make your item unique
        keyFields: ['id', 'street'],
      },
    },
  }),
});
export default client;
