import React from 'react';
import styles from './title-button.module.scss';
import { Icon } from 'vitl-component-library';

interface IProps {
  title: string;
  buttonLabel: string;
  onClickButton: any;
  disabled?: boolean;
  lock?: boolean;
}

const LockIcon = () => (
  <div style={{ display: 'none' }}>
    <Icon icon="lock" size={16} color="rgba(26, 26, 26, 0.5)" />
  </div>
);

const TitleButton: React.FC<IProps> = ({ title, buttonLabel, onClickButton, disabled, lock }) => {
  return (
    <div className={styles.title}>
      <h5>
        {title} {lock ? LockIcon() : null}
      </h5>
      <button className={styles.buttonLink} onClick={onClickButton} disabled={disabled}>
        {buttonLabel}
      </button>
    </div>
  );
};

export default TitleButton;
