import React, { useEffect } from 'react';
import FullPageMessage from 'components/core/FullPageMessage';

const LetmeinPage: React.FC = () => {
  useEffect(() => {
    document.cookie =
      'vitl-team=3aJCYm4CnbMhuhsttJWMqgKUH5EWaHsJ; expires=Sun, 1 Jan 2023 00:00:00 UTC; path=/; domain=vitl.com';
    window.location.assign('/');
  }, []);

  return (
    <FullPageMessage
      title="Redirecting..."
      message={'You must have javascript enabled.'}
    />
  );
};

export default LetmeinPage;
