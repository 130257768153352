export const SIGN_IN_FORM_INFO = {
  title: 'Sign in',
  subtitle: 'If you have an account, enter your details',
  inputs: [
    {
      id: 'email',
      type: 'email',
      label: 'Email',
      validation: {
        required: true,
      },
      errorMessage: {
        required: 'Please enter your email address',
      },
    },
  ],
  submitLabel: 'Login',
  successMessage: 'A sign in link has been sent to: ',
  cancelLabel: 'Continue as guest',
  textBetweenLabels: 'or',
};
