import { useQuery } from '@apollo/client';
import { USER_DETAILS } from 'queries/user';

const useUserDetails = () => {
  const { data, loading, error } = useQuery(USER_DETAILS);
  const userDetails = data?.user_details;

  return { userDetails, loading, error };
};

export { useUserDetails };
