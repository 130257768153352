import { useLazyQuery, useQuery } from '@apollo/client';

import { GET_BASKET } from 'queries/basket';
import { getBasketId, getCookie } from 'services/cookies';
import { IBasket, IBasketItem, IBasketQueryResponse } from '../interfaces/basket';
import client from '../apollo';
import { COOKIES } from '../constants/cookies';
import { IBasketPrice } from '../interfaces/basket';

const getBasketData = (data: IBasketQueryResponse) => {
  if (!data.basket_getBasket) return null;
  const basket: IBasket = data.basket_getBasket;
  const basketId = basket.basketId.toString();
  const basketPayment = basket.payment;
  const basketDeliveryAddress = basket.deliveryAddress;

  const basketPrice: IBasketPrice = {
    total: basket.total,
    subtotal: basket.subTotal,
    shipping: basket.shipping,
    discount: basket.discountAmount,
    currency: basket.currency,
    creditAvailable: basket.creditAvailable,
    creditAllocated: basket.creditAllocated,
    totalWithCreditAllocated: basket.totalWithCreditAllocated,
  };

  const basketItems: IBasketItem[] = basket.items;

  const hasBasketItems = basketItems.length;
  const hasBasketDeliveryAddress = basketDeliveryAddress.street && basketDeliveryAddress.street !== '-';
  const hasBasketPayment = basketPayment;

  return {
    basket,
    basketId,
    basketItems,
    basketPayment,
    basketDeliveryAddress,
    basketPrice,
    hasBasketItems,
    hasBasketDeliveryAddress,
    hasBasketPayment,
  };
};

const updateCachedBasket = (basketData: IBasket | null) => {
  client.writeQuery({
    query: GET_BASKET,
    data: { basket_getBasket: basketData },
    variables: {
      basketId: getCookie(COOKIES.basketId),
    },
  });
};
const useLazyBasket = () => {
  const basketId = getBasketId();

  const [getBasket, { data, loading, error }] = useLazyQuery<IBasketQueryResponse>(GET_BASKET, {
    variables: { basketId },
  });

  const basketData = data && getBasketData(data);

  return {
    getBasket,
    data: data?.basket_getBasket,
    error,
    loading,
    ...basketData
  };
};
const useBasket = () => {
  const { data, error, loading, refetch } = useQuery<IBasketQueryResponse>(GET_BASKET, {
    variables: { basketId: getBasketId() },
  });

  const basketData = data && getBasketData(data);

  return {
    data: data?.basket_getBasket,
    error,
    loading,
    refetch,
    ...basketData
  };
};

export { useLazyBasket, useBasket, updateCachedBasket };
