import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
// context
import { useGlobalContext } from '../context/global/global-context';
// services
import { getCookie, setCookie } from '../services/cookies';
// queries
import { DETECT_LOCALE } from '../queries/user';

const useLocale = () => {
  const { setCurrencyCode } = useGlobalContext();
  const cookieExpiry = 30 * 24 * 60 * 60; // 30 days
  const isCurrencySet = getCookie('currency');
  const isUserCountrySet = getCookie('userCountry');

  const [detectLocale, { loading, error }] = useLazyQuery(DETECT_LOCALE, {
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      const currency = String(res.guest_locale.currency.toUpperCase());
      setCookie('currency', currency, cookieExpiry);
      setCurrencyCode(currency);
      setCookie('userCountry', String(res.guest_locale.country), cookieExpiry);
    },
    onError: () => {
      setCookie('currency', 'GBP', cookieExpiry);
      setCookie('userCountry', 'GB', cookieExpiry);
    },
  });

  const detectUserLocale = useCallback(() => {
    if (!isCurrencySet || !isUserCountrySet) {
      detectLocale();
    }
  }, [detectLocale, isCurrencySet, isUserCountrySet]);

  return {
    detectUserLocale,
    loading,
    error,
  };
};

export { useLocale };
