import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { IAddress, IFormatAddress } from 'interfaces/address';
import {
  BASKET_APPLY_SHIPPING_ADDRESS,
  GET_BASKET,
  BASKET_SET_SHIPPING_ADDRESS,
} from 'queries/basket';
import { GET_SHIPPING_ADDRESSES, STORE_COUNTRIES } from 'queries/delivery';
import { USER_DETAILS, USER_UPDATE_DETAILS } from 'queries/user';
import { getBasketId } from 'services/cookies';
import { VIEWS } from 'views/Overview/constants';
import { useBasket } from './useBasket';
import { joinParamsWithUnderscore } from 'services/format';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';
import { trackIdentify } from 'services/tracking';
import { useGlobalContext } from 'context/global/global-context';

const useEstimateShipping = (onComplete?: any) => {
  const [estimateShippingCosts, { loading, error }] = useMutation(BASKET_APPLY_SHIPPING_ADDRESS, {
    onCompleted: () => onComplete && onComplete(),
    onError: error => {
      throw error;
    },
  });
  return { estimateShippingCosts, loading, error };
};

const useAddShippingAddress = (onComplete?: any) => {
  const [addShippingAddress, { loading, error }] = useMutation(BASKET_APPLY_SHIPPING_ADDRESS, {
    update: (cache, response) => {
      const data: any = cache.readQuery({
        query: GET_BASKET,
        variables: { basketId: getBasketId() },
      });
      const newDeliveryAddress = response?.data?.basket_applyShippingAddress?.deliveryAddress;

      const newDataBasket = {
        ...data,
        basket_getBasket: {
          ...data.basket_getBasket,
          deliveryAddress: newDeliveryAddress,
        },
      };

      cache.writeQuery({
        query: GET_BASKET,
        variables: { id: getBasketId() },
        data: newDataBasket,
      });
    },
    onCompleted: () => onComplete && onComplete(),
    onError: error => {
      throw error;
    },
  });
  return { addShippingAddress, loading, error };
};

const useDelivery = (setView: any) => {
  const [loadingRefetch, setLoadingRefetch] = useState(false);

  //queries
  const { loading: loadingBasket, refetch: refetchBasket, basketDeliveryAddress } = useBasket();
  const {
    loading: loadingUserDeliveryAddresses,
    // error: errorUserDeliveryAddresses,
    data: userDeliveryAddresses,
  } = useQuery(GET_SHIPPING_ADDRESSES);

  const { data: countriesData, loading: loadingCountries } = useQuery(STORE_COUNTRIES);

  const countries = countriesData?.store_countries
    .filter((c: any) => c.name)
    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
    .map((c: any) => ({ ...c, label: c.name, value: c.code }));

  const loading =
    loadingBasket || loadingUserDeliveryAddresses || loadingCountries || loadingRefetch;

  const hasUserDeliveryAddress = userDeliveryAddresses?.user_deliveryAddresses?.length;

  const onComplete = () => {
    //Delivery address affects shipping costs. We need to refetch the basket after changing it
    setLoadingRefetch(true);
    refetchBasket().then(() => {
      setView(VIEWS.delivery.address);
      setLoadingRefetch(false);
    });
  };

  return {
    countries,
    loading,
    hasUserDeliveryAddress,
    basketDeliveryAddress,
    onComplete,
  };
};

const useDeliveryAdd = (onComplete?: any) => {
  // state
  const [addressData, setAddressData] = useState<IFormatAddress>({} as IFormatAddress);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const { setShowSmsOptInModal, isOptInToSmsAccepted } = useGlobalContext();

  const { basketId } = useBasket();
  const { data: userData, loading: loadingUserDetails } = useQuery(USER_DETAILS);
  const {
    addShippingAddress,
    loading: loadingAddAddress,
    error: errorAddAddress,
  } = useAddShippingAddress(onComplete);

  const [updateUserDetails] = useMutation(USER_UPDATE_DETAILS);

  const [userFirstName, setUserFirstName] = useState(userData?.user_details?.firstName);
  const [userLastName, setUserLastName] = useState(userData?.user_details?.lastName);

  useEffect(() => {
    setUserFirstName(userData?.user_details?.firstName);
    setUserLastName(userData?.user_details?.lastName);
  }, [userData, setUserFirstName, setUserLastName]);

  const handleSelectAddress = (address: IFormatAddress) => {
    setAddressData(address);
    setIsManualAddress(true);
  };

  const handleSmsOptIn = (
    optInPhone: string | null,
    userPhone: string | null,
    customerId: string
  ) => {
    if (!optInPhone || !userPhone) {
      return false;
    }

    if (optInPhone === userPhone) {
      trackIdentify(customerId, {
        smsSubscribed: true,
      });
      return false;
    }

    if (userPhone !== '00000000000') {
      // should show popup
      setShowSmsOptInModal(true);
      return true;
    }
  };

  const handleAddManualDeliveryAddressSubmit = (formData: IAddress) => {
    const { firstName, lastName, street, city, postcode, countryCode, phone, smsOptIn } = formData;

    if (smsOptIn && !isOptInToSmsAccepted.current && userData.user_details) {
      const smsOptInPopupShown = handleSmsOptIn(
        phone,
        userData.user_details.phone,
        userData.user_details.customerDataPlatformId
      );
      if (smsOptInPopupShown) {
        return;
      }
    }

    if (isOptInToSmsAccepted.current) {
      updateUserDetails({
        variables: {
          phone,
        },
      });
      trackGAEvent('checkout_phonenumber');
      trackHeap('checkout_phonenumber');
    }

    if (smsOptIn && userData.user_details) {
      trackIdentify(userData.user_details.customerDataPlatformId, {
        smsSubscribed: true,
      });
    }

    addShippingAddress({
      variables: {
        basketId,
        firstName,
        lastName,
        street,
        city,
        postcode,
        countryId: countryCode,
        phone,
        smsOptIn,
      },
    });
  };

  return {
    userFirstName,
    userLastName,
    setUserFirstName,
    setUserLastName,
    isManualAddress,
    setIsManualAddress,
    addressData,
    handleSelectAddress,
    handleAddManualDeliveryAddressSubmit,
    loadingUserDetails,
    loadingAddAddress,
    errorAddAddress,
  };
};

const useDeliverySelection = (onComplete: any) => {
  const { basketDeliveryAddress, loading: loadingBasket } = useBasket();

  //Workaround to be updated. More info at FE-173
  const [selectedAddressId, setAddressId] = useState(
    basketDeliveryAddress &&
      joinParamsWithUnderscore([basketDeliveryAddress?.id, basketDeliveryAddress?.street])
  );

  const {
    loading: loadingUserAddresses,
    data: { user_deliveryAddresses: userAddresses },
  } = useQuery(GET_SHIPPING_ADDRESSES);

  const [
    setShippingAddress,
    { data: dataShippingAddress, loading: loadingShippingAddress, error: errorShippingAddress },
  ] = useMutation(BASKET_SET_SHIPPING_ADDRESS, {
    update: (cache, response) => {
      if (!response.data) return;
      const data: any = cache.readQuery({
        query: GET_BASKET,
        variables: { basketId: getBasketId() },
      });
      const newDeliveryAddress = response.data.basket_setShippingAddress?.deliveryAddress;

      const updatedBasketData = {
        basket_getBasket: {
          ...data.basket_getBasket,
          deliveryAddress: newDeliveryAddress,
        },
      };

      cache.writeQuery({
        query: GET_BASKET,
        variables: { id: getBasketId() },
        data: updatedBasketData,
      });
    },
    onCompleted: () => {
      onComplete();
      trackGAEvent('add_shipping_info');
      trackHeap('add_shipping_info');
    },
    onError: () => {
      // setErrorModalMessage(ERROR_MESSAGES.setShippingAddress);
    },
  });

  const selectDeliveryAddress = (formData: any) => {
    const basketId = getBasketId();
    setAddressId(formData.addressId);

    //Workaround to be updated. More info at FE-173
    const realId = formData.addressId.slice(0, formData.addressId.lastIndexOf('_'));

    setShippingAddress({
      variables: {
        addressId: realId,
        basketId,
      },
    });
  };

  const data = dataShippingAddress;
  const error = errorShippingAddress;
  const loading = loadingShippingAddress || loadingBasket;

  return {
    data,
    error,
    loading,
    loadingUserAddresses,
    userAddresses,
    selectedAddressId,
    selectDeliveryAddress,
  };
};

export {
  useEstimateShipping,
  useAddShippingAddress,
  useDelivery,
  useDeliveryAdd,
  useDeliverySelection,
};
