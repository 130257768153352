import React from 'react';
// components
import Footer from './Footer';
// styles
import styles from './layout.module.scss';

interface IProps {
  left: React.ReactElement;
  right: React.ReactElement;
  bgColor?: string;
}

const Layout: React.FC<IProps> = ({ left, right, bgColor = '#ffd326' }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layoutBg} style={{ backgroundColor: bgColor }}></div>
      <div className={styles.layoutContainer}>
        <div className={styles.layout__left}>
          <div className={styles.left__container}>{left}</div>
        </div>
        <div className={styles.layout__right}>
          <div className={styles.right__container}>{right}</div>
        </div>
        <Footer />
      </div>
      <div id="modalContainer" />
    </div>
  );
};

export default Layout;
