import React, { createContext, useContext } from 'react';
import { IGlobal } from 'interfaces/context';
import { useGlobalState } from './state';

// Context
const GlobalContext = createContext<IGlobal>({} as IGlobal);

// Provider
const GlobalContextProvider: React.FC = ({ children }) => {
  const state = useGlobalState();

  return (
    <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>
  );
};

const useGlobalContext = () => useContext(GlobalContext);

// Export
export { GlobalContextProvider, useGlobalContext };
