import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonForm = () => {
  return (
    <div>
      <Skeleton width={`100%`} style={{ marginBottom: 8 }} />
      <Skeleton width={`100%`} style={{ marginBottom: 16 }} />
      <Skeleton width={`100%`} height={'56px'} style={{ marginBottom: 16 }} />
      <Skeleton width={`100%`} height={'56px'} style={{ marginBottom: 16 }} />
    </div>
  );
};
export default SkeletonForm;
