import gql from 'graphql-tag';
import { userDetailsFragment } from './fragments';

export const SEND_MAGIC_LINK = gql`
  mutation user_sendMagicLink($email: String!, $destination: String) {
    user_sendMagicLink(email: $email, destination: $destination)
  }
`;

export const MAGIC_SIGN_IN = gql`
  mutation user_exchangeMagicLoginToken($magicLinkToken: String!) {
    user_exchangeMagicLoginToken(magicLinkToken: $magicLinkToken) {
      token
      customerDataPlatformId
    }
  }
`;

export const USER_DETAILS = gql`
  query user_details {
    user_details {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export const REGISTER_USER = gql`
  mutation user_register(
    $email: String!
    $firstName: String!
    $leadAttribution: [KeyValueInput]
  ) {
    user_register(
      email: $email
      firstName: $firstName
      leadAttribution: $leadAttribution
    ) {
      token
      customerDataPlatformId
    }
  }
`;

export const DETECT_LOCALE = gql`
  query guest_locale {
    guest_locale {
      currency
      country
    }
  }
`;

export const USER_UPDATE_DETAILS = gql`
  mutation user_updateDetails(
    $firstName: String,
    $lastName: String,
    $password: String,
    $dob: String,
    $phone: String
  ) {
    user_updateDetails(
      firstName: $firstName
      lastName: $lastName
      password: $password
      dob: $dob
      phone: $phone
    ) {
      firstName
      lastName
      dob
      phone
      customerDataPlatformId
    }
  }
`
