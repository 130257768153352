import Error from 'components/core/Error';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
// services
import { formatGraphQLError } from 'services/format';
// elements
import CustomAddressSelector from '../elements/CustomAddressSelector';
import CustomInput from '../elements/CustomInput';
import CustomRadio from '../elements/CustomRadio';
import SubmitButton from '../elements/SubmitButton';
import CustomReactSelect from '../elements/CustomReactSelect';
// styles
import styles from './default-form.module.scss';
import PhoneNumberInput from '../elements/PhoneNumberInput';
import CustomCheckbox from '../elements/CustomCheckbox';
import { CSSTransition } from 'react-transition-group';
import Modal from 'components/core/Modal/Modal';
import SmsOptInModal from 'components/feature/Address/SmsOptInModal/SmsOptInModal';
import { useGlobalContext } from 'context/global/global-context';

interface IProps {
  formInfo: {
    id?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    inputs?: any;
    setValues?: any;
    errorMessage?: any;
    successMessage?: string;
    submitLabel?: string;
    cancelLabel?: string;
    textBetweenLabels?: string;
    defaultValues?: any;
  };
  onInput?: any;
  onSubmit?: (formData: any) => void;
  onCancel?: (formData: any) => void;
  loading?: boolean;
  disabled?: boolean;
  error?: any | undefined;
  data?: any;
  overflow?: boolean;
  className?: string;
}

const DefaultForm: React.FC<IProps> = ({
  formInfo: {
    id = '',
    title,
    subtitle,
    description,
    inputs,
    setValues,
    errorMessage,
    successMessage,
    submitLabel,
    cancelLabel,
    textBetweenLabels,
    defaultValues,
  },
  onInput = () => undefined,
  onSubmit = () => undefined,
  onCancel,
  data,
  loading,
  disabled = false,
  error,
  overflow = false,
  className,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({ defaultValues });
  const { showSmsOptInModal, setShowSmsOptInModal, isOptInToSmsAccepted } = useGlobalContext();

  useEffect(() => {
    if (defaultValues) return;
    if (setValues) {
      setValues.forEach((e: any) =>
        setValue(e?.name, e?.value, {
          shouldDirty: true,
        })
      );
    }
  }, [setValues, setValue, defaultValues]);

  const onInputHandler = (name: string, value: string) => {
    onInput(name, value);
    setValue(name, value);
  };

  const handleAcceptOptIn = () => {
    isOptInToSmsAccepted.current = true;
    setShowSmsOptInModal(false);
    handleSubmit(onSubmit)();
  }

  const handleDenyOptIn = () => {
    isOptInToSmsAccepted.current = false;
    setShowSmsOptInModal(false);
    setValue('smsOptIn', false);
  }

  const renderInput = (input: any) => {
    return input.type === 'radio' ? (
      <CustomRadio
        key={input.id}
        name={input.name}
        id={input.id}
        register={register(input.name, input.validation)}
        label={input.label}
      />
    ) : input.type === 'select' ? (
      <CustomReactSelect
        key={input.id}
        id={input.id}
        label={input.label}
        control={control}
        defaultValue={watch(input.id)}
        options={input.options}
        error={errors.countryCode}
      />
    ) : input.type === 'address' ? (
      <CustomAddressSelector
        key={input.id}
        id={input.id}
        register={register(input.id, input.validation)}
        label={input.label}
        hasValue={!!watch(input.id)}
        error={errors[input.id]}
        errorMessage={input.errorMessage[errors[input.id]?.type]}
        onInput={(value: string) => onInputHandler(input.id, value)}
        onClick={input.onClickAddress}
        addressLookupMessage={input.addressLookupMessage}
        foundAddresses={input.foundAddresses}
      />
    ) : input.type === 'component' ? (
      <div key={input.id}>{input.component}</div>
    ) : input.type === 'phone' ? (
      <div key={input.id}>
        <PhoneNumberInput
          id={input.id}
          control={control}
          validation={input.validation}
          defaultValue={watch(input.id)}
          label={input.label}
          hasValue={!!watch(input.id)}
          autoComplete={input.autoComplete}
          countryCallingCodeEditable={input.countryCallingCodeEditable}
          error={errors[input.id]}
        />
        <p className={styles.inputNote}>{input.note}</p>
      </div>
    ) : input.type === 'checkbox' ? (
      <CustomCheckbox
        key={input.id}
        id={input.id}
        label={input.label}
        name={input.name}
        register={register(input.id, input.validation)}
      />
    ) : (
      <CustomInput
        key={input.id}
        id={input.id}
        type={input.type}
        onInput={(value: string) => onInputHandler(input.id, value)}
        register={register(input.id, input.validation)}
        label={input.label}
        hasValue={!!watch(input.id)}
        error={errors[input.id]}
        errorMessage={input.errorMessage[errors[input.id]?.type]}
      />
    );
  };

  return (
    <div>
      <div className={`${className ? className : ''} ${overflow ? styles.overflow : ''}`}>
        {title && <h5>{title}</h5>}
        {subtitle && <p>{subtitle}</p>}
        {description && <p>{description}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={loading}>
            <div>
              {inputs?.map((input: any, i: any) => {
                if (Array.isArray(input)) {
                  return (
                    <div key={i} className={styles.singleRow}>
                      {input.map((el: any) => renderInput(el))}
                    </div>
                  );
                } else return renderInput(input);
              })}
            </div>
            <div className={styles.buttons}>
              {submitLabel && (
                <>
                  {overflow ? (
                    <SubmitButton
                      loading={loading}
                      disabled={disabled || Object.values(errors).length > 0}
                      buttonLabel={submitLabel}
                      fullWidth
                    />
                  ) : (
                    <SubmitButton
                      loading={loading}
                      disabled={disabled || Object.values(errors).length > 0}
                      success={data}
                      successMessage={successMessage}
                      error={error}
                      buttonLabel={submitLabel}
                      fullWidth
                    />
                  )}
                </>
              )}
              {textBetweenLabels && <p className={styles.textBetweenLabels}>{textBetweenLabels}</p>}
              {cancelLabel ? (
                <button className={styles.buttonInverted} type="button" onClick={onCancel}>
                  {cancelLabel}
                </button>
              ) : null}
            </div>
          </fieldset>
        </form>
      </div>
      {overflow && !loading && <Error data={error} />}
      <CSSTransition in={showSmsOptInModal} timeout={300} unmountOnExit>
        <Modal>
          <SmsOptInModal onAccept={handleAcceptOptIn} onDeny={handleDenyOptIn} />
        </Modal>
      </CSSTransition>
    </div>
  );
};

export default DefaultForm;
