import React, { useMemo } from 'react';
import CardDetails from 'components/feature/CardDetails';
import DefaultForm from 'components/form/DefaultForm';
import { SkeletonForm } from 'components/core/skeletons';
import TitleButton from 'components/core/TitleButton';
import { PAYMENT_SELECTION_FORM } from './data';
import { usePaymentSelection } from 'hooks/usePayment';
import { useGlobalContext } from '../../../../context/global/global-context';

interface IProps {
  onComplete: any;
  onAddNew: any;
  onClose: any;
}

const PaymentSelection: React.FC<IProps> = ({ onComplete, onAddNew, onClose }) => {
  const {
    data,
    error,
    loading: loadingPaymentSelection,
    paymentMethods,
    basketPaymentId,
    setPaymentMethod,
    preselectedPaymentMethod,
  } = usePaymentSelection(onComplete);

  const { isLoadingPayment } = useGlobalContext();

  const loading = loadingPaymentSelection || isLoadingPayment;

  const inputs = paymentMethods
    ?.filter((p: any) => p.id)
    ?.map((paymentMethod: any) => {
      return {
        id: paymentMethod?.id,
        type: 'radio',
        label: (
          <CardDetails
            loading={loading}
            cardIssuer={paymentMethod?.cardIssuer}
            lastFourDigits={paymentMethod?.lastFourDigits}
          />
        ),
        name: 'paymentMethod',
        validation: { required: true },
      };
    });

  const formInfo = {
    ...PAYMENT_SELECTION_FORM,
    inputs,
    setValues: [
      {
        name: 'paymentMethod',
        value: basketPaymentId,
      },
    ],
    errorMessage: {
      required: 'Please select an address',
    },
    defaultValues: {
      paymentMethod: preselectedPaymentMethod,
    },
  };

  const handleSubmit = (formData: any) => setPaymentMethod(formData.paymentMethod);

  useMemo(() => {
    if (!basketPaymentId && preselectedPaymentMethod) {
      setPaymentMethod(preselectedPaymentMethod);
    }
  }, [basketPaymentId, preselectedPaymentMethod, setPaymentMethod]);

  if (loading) return <SkeletonForm />;

  return (
    <>
      <TitleButton
        title="Choose your payment method"
        buttonLabel="Cancel"
        onClickButton={onClose}
      />
      <DefaultForm
        {...{
          formInfo,
          onSubmit: handleSubmit,
          overflow: true,
          onCancel: onAddNew,
          data,
          loading,
          error,
        }}
      />
    </>
  );
};

export default PaymentSelection;
