import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { getCookie } from '../../../../services/cookies';
import styles from './phone-number-input.module.scss';
import { COOKIES } from '../../../../constants/cookies';
import { formatPhoneErrorMessage } from '../../../../services/format';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useClickOutside } from '../../../../hooks/useClickOutsideHook';

interface IProps {
  id: string;
  label: string;
  autoComplete: string;
  control: any;
  countryCallingCodeEditable?: boolean;
  error?: any;
  hasValue?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: any;
  validation: { required: boolean };
}

const PhoneNumberInput: React.FC<IProps> = ({
  id,
  label,
  autoComplete = 'on',
  control,
  countryCallingCodeEditable = false,
  error,
  defaultValue,
  validation,
  hasValue,
}) => {
  const tooltipRef = React.useRef<HTMLInputElement>(null);

  const [showTooltip, setShowTooltip] = useState(false);

  useClickOutside(tooltipRef, () => setShowTooltip(false));

  const errorMessage =
    error &&
    formatPhoneErrorMessage(
      error as {
        type: string;
      }
    );

  return (
    <div className={styles.phoneNumberInputWrapper}>
      <div className={!error ? styles.phoneInputContainer : styles.phoneInputErrorContainer}>
        <PhoneInputWithCountry
          type="tel"
          id={id}
          name={id}
          defaultValue={defaultValue}
          control={control}
          rules={{
            ...validation,
            validate: hasValue && isValidPhoneNumber,
          }}
          className={styles.phoneInput}
          autoComplete={autoComplete}
          international={true}
          countryCallingCodeEditable={countryCallingCodeEditable}
          // @ts-ignore
          defaultCountry={getCookie(COOKIES.userCountry) || 'GB'}
        />
        <div ref={tooltipRef} className={styles.tooltip} onClick={() => setShowTooltip(!showTooltip)}>
          i{showTooltip && <div className={styles.tooltipText}>Used to update you on your order & shipping.</div>}
        </div>
        <label htmlFor={id} className={styles.phoneInputPlaceholder}>
          {label}
        </label>
      </div>
      {error && <small className={styles.phoneInputError}>{errorMessage}</small>}
    </div>
  );
};
export default PhoneNumberInput;
