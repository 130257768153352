import React from 'react';

import styles from './basket-button-view-details.module.scss';
import { useGlobalContext } from 'context/global/global-context';
import { IBasketButtonViewDetails } from 'interfaces/basket';
import BasketOrderDetails from '../BasketOrderDetails';

const BasketButtonViewDetails: React.FC<IBasketButtonViewDetails> = ({
  items,
  price,
  name,
  hasDeliveryAddress,
}) => {
  const { modal, setModal } = useGlobalContext();

  const handleOnClick = () => {
    if (modal.isOpen) {
      setModal({
        ...modal,
        closeModal: true,
      });
    } else {
      setModal({
        isOpen: true,
        modalContent: (
          <BasketOrderDetails {...{ items, price, hasDeliveryAddress }} />
        ),
      });
    }
  };
  return (
    <button className={styles.buttonDetails} onClick={handleOnClick}>
      {name || (
        <>
          <span>View details</span>
          <span className={styles.arrowDown}></span>
        </>
      )}
    </button>
  );
};

export default BasketButtonViewDetails;
