import { CURRENCY_MAP } from '../constants/global';

export const formatPrice = (price: number, currency: string) => {
  if (!currency) {
    return '';
  }

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  }).format(price);
};

export const formatGraphQLError = (error: any) =>
  error
    .toString()
    .replace('Error: GraphQL error: ', '')
    .replace('ApolloError: ', '')
    .replace('GraphQL error: ', '');

export const formatPasswordErrorMessage = ({ type }: { type: string }) => {
  if (type === 'required') return 'Please enter your password';
  else if (type === 'minLength')
    return 'Password must be at least 6 characters long';
  else return "Passwords don't match";
};

export const formatCurrency = (currencyCode: string) => {
  const currencyItem = CURRENCY_MAP.find(
    currencyItem => currencyItem.currencyCode === currencyCode
  );
  return currencyItem?.currencySymbol;
};

export const joinParamsWithUnderscore = (params: Array<String>) => {
  let result = params.join('_');
  result = result.replace(/\s/g, '');
  return result;
};

export const formatStringToBoolean = (variable: string | undefined) =>
  variable?.toLowerCase() === 'true';

export const formatPhoneErrorMessage = ({ type }: { type: string }) => {
  if (type === 'required') return 'Please enter your phone number';
  else if (type === 'validate') return 'Please enter a valid phone number';
};