import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
// styles
import styles from './modal.module.scss';
import { Icon } from 'vitl-component-library';

interface IProps {
  type?: string;
  children: any;
  handleClose?: () => void;
}

const Modal: React.FC<IProps> = ({ type = '', children, handleClose }) => {
  const modalRoot: HTMLElement | null = document.getElementById('modalContainer');

  useEffect(() => {
    document.body.classList.add('body-fixed');

    return () => {
      document.body.classList.remove('body-fixed');
    };
  });

  const modalContent = (
    <div className={styles.wrapper}>
      <div className={styles['container' + type]}>
        {handleClose && (
          <button type="button" className={styles['button-close']} onClick={handleClose}>
            <Icon icon="close" size={16} />
          </button>
        )}
        {children}
      </div>
    </div>
  );

  return modalRoot ? ReactDOM.createPortal(modalContent, modalRoot) : null;
};

export default Modal;