export const VIEWS = {
  delivery: {
    add: 'deliveryAdd',
    selection: 'deliverySelection',
    address: 'deliveryAddress',
    default: 'deliveryAddress',
    hidden: 'deliveryHidden',
  },
  payment: {
    add: 'paymentAdd',
    selection: 'paymentSelection',
    pay: 'paymentPay',
    default: 'paymentPay',
  },
};
