import React from 'react';
import BasketSummary from 'components/feature/BasketSummary';
import { ACCOUNT_INFO } from 'constants/account';
import { useBasket } from 'hooks/useBasket';

const Basket: React.FC = () => {
  const { basketPrice, basketItems, hasBasketDeliveryAddress,  } = useBasket();
  if (!basketPrice || !basketItems?.length) return null;
  const basketInfo = {
    price: basketPrice,
    items: basketItems,
    hasDeliveryAddress: !!hasBasketDeliveryAddress,
  };

  return <BasketSummary {...ACCOUNT_INFO} {...basketInfo} />;
};

export default Basket;
