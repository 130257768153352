import React, { useEffect } from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { navigate } from '@reach/router';
import { TrustPilot, Icon } from 'vitl-component-library';
//components
import Layout from 'components/core/Layout';
import { SkeletonApp } from 'components/core/skeletons';
import Error from 'components/core/Error';
import FullPageMessage from 'components/core/FullPageMessage';
//views
import { SignIn, Overview, Basket } from 'views';
//context
import { useGlobalContext } from 'context/global/global-context';
//constants
import { ACCOUNT_INFO } from 'constants/account';
//hooks
import { useLazyBasket } from 'hooks/useBasket';
import { useLogin } from 'hooks/useLogin';
import { useWallet } from 'hooks/useWallet';
import { useLocale } from '../../hooks/useLocale';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
//style
import styles from './app.module.scss';

const App: React.FC = () => {
  const { displayName, urls } = ACCOUNT_INFO;
  const { isLoading } = useGlobalContext();

  const { getBasket: onCompleteLogin, loading: loadingBasket, error: errorBasket, hasBasketItems } = useLazyBasket();

  const { paymentRequest: walletPR, loading: loadingWalletPR, error: errorWallet } = useWallet();

  const { isLoggedIn, error: errorLogin, loading: loadingLogin, logInFlow } = useLogin(onCompleteLogin);

  const { detectUserLocale, loading: loadingDetectLocale, error: errorDetectLocale } = useLocale();

  const { getFeatureFlags, loadingFeatureFlags } = useFeatureFlags();

  useEffect(() => {
    getFeatureFlags();
    detectUserLocale();
    logInFlow();
  }, [getFeatureFlags, detectUserLocale, logInFlow]);

  const error = errorLogin || errorBasket || errorDetectLocale;
  const loading =
    loadingWalletPR || loadingLogin || loadingBasket || loadingDetectLocale || loadingFeatureFlags || isLoading;

  const leftController = () => {
    if (!hasBasketItems)
      return (
        <p>
          Your basket is empty. To make an order go to <a href={urls.url_cancel}>{displayName}</a>
        </p>
      );
    return <Basket />;
  };

  const rightController = () => {
    if (!hasBasketItems) return <></>;
    return (
      <>
        {walletPR && (
          <>
            <div className={styles.walletButton}>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest: walletPR,
                  style: {
                    paymentRequestButton: {
                      type: 'default',
                      height: '50px',
                    },
                  },
                }}
              />
            </div>
            <div className={styles.textWallet}>
              <p>
                {`or ${
                  isLoggedIn ? 'enter your shipping and payment details below' : 'enter your sign in details below'
                }`}
              </p>
              {errorWallet && <Error data={errorWallet} />}
            </div>
          </>
        )}
        <div className={`${walletPR ? styles.overviewWithWallet : ''}`}>
          {isLoggedIn ? <Overview /> : <SignIn />}
          <div className={styles.trustpilot} style={{ display: 'none' }}>
            <div>
              <TrustPilot type="MicroStar" className={styles.trustpilotWidget} />
            </div>
            <p>
              <Icon icon="check" size={12} color="#6F6F6F" />
              Safe & secure payments, always
            </p>
            <p>
              <Icon icon="check" size={12} color="#6F6F6F" /> Free delivery on subscriptions/orders over £20
            </p>
            <p>
              <Icon icon="check" size={12} color="#6F6F6F" /> Pause or cancel subscriptions at anytime
            </p>
          </div>
        </div>
      </>
    );
  };

  if (error)
    return (
      <FullPageMessage
        title="Something went wrong"
        message={<Error data={error} defaultStyles={false} />}
        onBack={() => navigate(urls.url_cancel)}
      />
    );
  if (loading) return <SkeletonApp />;

  return <Layout left={leftController()} right={rightController()}></Layout>;
};

export default App;
