export const HEADER_HEIGHT = 60;
export const CURRENCY_MAP = [
  {
    currencyCode: 'GBP',
    currencySymbol: '£',
  },
  {
    currencyCode: 'EUR',
    currencySymbol: '€',
  },
  {
    currencyCode: 'USD',
    currencySymbol: '$',
  },
  {
    currencyCode: 'AUD',
    currencySymbol: '$',
  },
];
