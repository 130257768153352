import { CURRENCY_MAP } from 'constants/global';
import { ICountry } from 'interfaces/address';
import { getCurrency } from './cookies';

export const isEmpty = (obj: any) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const getCountryNameById = (
  countries: Array<ICountry>,
  countryId: string
) => {
  const countryName =
    countryId === 'UK'
      ? 'United Kingdom'
      : countries?.find((country: ICountry) => country.id === countryId)?.name;

  return countryName || countryId;
};

export const getCurrencySimbolByCode = (code: string = getCurrency()) => {
  return CURRENCY_MAP.find(e => e.currencyCode === code)?.currencySymbol;
};
