import React, { useEffect } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
// context
import DefaultForm from 'components/form/DefaultForm';
import { PAYMENT_ADD_FORM_DATA } from './data';

import style from 'components/form/elements/CustomInput/custom-input.module.scss';
import TitleButton from 'components/core/TitleButton';
import { usePaymentAddAndPay } from 'hooks/usePayment';
import useRestrictedItems from 'hooks/useRestrictedItems';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';
import PaymentCards from 'components/core/paymentCards/paymentCards';

interface IProps {
  onComplete: any;
  onClose: any;
}

const PaymentAddAndPay: React.FC<IProps> = ({ onComplete, onClose }) => {
  const {
    loading: loadingAddApplyPayment,
    error,
    paymentLabel,
    addPaymentMethod,
  } = usePaymentAddAndPay(onComplete);

  const { restricted, country, loading: loadingProductRestrictions } = useRestrictedItems();

  useEffect(() => {
    trackGAEvent('checkout_payment');
    trackHeap('checkout_payment');
  }, []);

  const getRestrictedError = () => {
    if (restricted) {
      return `Some items are unavailable for delivery to ${country}. Please remove them first!`;
    }
    return false;
  };

  const formInfo = {
    ...PAYMENT_ADD_FORM_DATA,
    inputs: [
      ...PAYMENT_ADD_FORM_DATA.inputs,
      {
        id: 'card',
        type: 'component',
        component: (
          <div className={style.stripeContainer}>
            <CardElement
              className={style.input}
              options={{
                style: {
                  base: {
                    lineHeight: '56px',
                  },
                },
              }}
            />
          </div>
        ),
      },
    ],
    submitLabel: paymentLabel,
  };

  const loading = loadingAddApplyPayment || loadingProductRestrictions;

  return (
    <>
      <TitleButton title="Payment" lock onClickButton={onClose} buttonLabel={'Close'} />
      <PaymentCards style={{ display: 'none' }} />
      <DefaultForm
        {...{
          formInfo,
          onSubmit: addPaymentMethod,
          loading,
          error: error || getRestrictedError(),
          disabled: !!restricted,
          // onCancel,
        }}
      />
    </>
  );
};

export default PaymentAddAndPay;
