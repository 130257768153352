import React, { useEffect, useState } from 'react';

import BackIcon from 'assets/img/back-icon.svg';
import styles from './basket-header.module.scss';
import { useGlobalContext } from 'context/global/global-context';
import { IBasketHeader } from 'interfaces/basket';
import BasketButonViewDetails from '../BasketButtonViewDetails/BasketButonViewDetails';
import { HEADER_HEIGHT } from 'constants/global';

const BasketHeader: React.FC<IBasketHeader> = ({
  logo,
  urls,
  items,
  price,
  hasDeliveryAddress,
}) => {
  const {
    modal: { isOpen: isModalOpen },
  } = useGlobalContext();

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > HEADER_HEIGHT);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`${styles.basketHeader} ${
        isModalOpen ? styles.basketHeader__modal : ''
      } ${scroll ? styles.scrolled : ''}`}
    >
      <div className={styles.header__container}>
        <a href={urls.url_cancel}>
          <img
            className={styles.header__backIcon}
            src={BackIcon}
            alt="back"
          ></img>
          <span className={styles.header__back}>Back</span>
          <img className={styles.header__logo} src={logo} alt="logo"></img>
        </a>
        <BasketButonViewDetails
          {...{
            items,
            price,
            hasDeliveryAddress,
            name: isModalOpen ? (
              <>
                <span>Close</span>
                <span className={styles.arrowUp}></span>
              </>
            ) : (
              <>
                <span>Details</span>
                <span className={styles.arrowDown}></span>
              </>
            ),
          }}
        />
      </div>
    </header>
  );
};

export default BasketHeader;
