import React from 'react';
import styles from './button.module.scss';

interface IProps {
  label: any;
  type: string;
  onClick: () => void;
  [x: string]: any;
}

const Button: React.FC<IProps> = ({ label, type, onClick, ...props }) => {
  return (
    <button {...{ onClick, ...props }} className={styles[`button-${type}`]}>
      {label}
    </button>
  );
};

export default Button;
