import { getCookie, setCookie, removeCookie } from 'services/cookies';
import client from 'apollo/client';
import { COOKIES, COOKIE_EXPIRES_IN } from 'constants/cookies';

export const setToken = (token: string) => {
  setCookie(COOKIES.token, token, COOKIE_EXPIRES_IN.thirtyDays);
};

export const removeToken = () => {
  removeCookie(COOKIES.token);
  removeCookie(COOKIES.guestToken);
};

export const getToken = () => {
  const token = getCookie(COOKIES.token);
  return !!token;
};

export const logout = () => {
  removeToken();
  removeCookie(COOKIES.basketId);
  client.clearStore();
};
