const url_base = process.env.REACT_APP_ACCOUNT_URL_BASE || '';

export const ACCOUNT_INFO = {
  displayName: process.env.REACT_APP_ACCOUNT_DISPLAY_NAME || '',
  logo: process.env.REACT_APP_ACCOUNT_LOGO || '',
  urls: {
    url_base: url_base,
    url_terms: `${url_base}${process.env.REACT_APP_ACCOUNT_URL_TERMS}` || '',
    url_privacy:
      `${url_base}${process.env.REACT_APP_ACCOUNT_URL_PRIVACY}` || '',
    url_cancel: `${url_base}${process.env.REACT_APP_ACCOUNT_URL_CANCEL}` || '',
    url_success:
      `${url_base}${process.env.REACT_APP_ACCOUNT_URL_SUCCESS}` || '',
  },
};
