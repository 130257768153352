import React from 'react';

import DefaultForm from 'components/form/DefaultForm';

import { GUEST_FORM_INFO } from './data';
import { IContinueAsGuestFormData, ISignInForm } from 'interfaces/signin';
import { useLogin } from 'hooks/useLogin';

import { useRegisterUser } from 'hooks/useRegisterUser';
import { useGlobalContext } from 'context/global/global-context';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

const ContinueAsGuestForm: React.FC<ISignInForm> = ({ onCancel }) => {
  const { loading: loadingLogin, error: errorLoading, logInFlow } = useLogin();
  const { logIn } = useGlobalContext();
  // queries
  const {
    handleRegisterUser,
    loading: loadingRegister,
    error: errorRegister,
  } = useRegisterUser();

  // handle submit functions
  const onSubmit = async (formData: IContinueAsGuestFormData) => {
    trackGAEvent('checkout_login_guest');
    trackHeap('checkout_login_guest');
    try {
      await handleRegisterUser(formData.firstName, formData.email);
      logInFlow();
      logIn();
    } catch (error) { }
  };

  const loading = loadingRegister || loadingLogin;
  const error = errorRegister || errorLoading;

  return (
    <DefaultForm
      {...{
        formInfo: GUEST_FORM_INFO,
        onSubmit,
        onCancel,
        loading,
        error,
      }}
    />
  );
};

export default ContinueAsGuestForm;
