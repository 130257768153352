import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { PaymentIntent, PaymentIntentResult, StripeCardElement } from '@stripe/stripe-js';

import {
  GET_BASKET,
  BASKET_REMOVE_PAYMENT_INTENT,
  BASKET_ORDER,
  BASKET_SET_PAYMENT_METHOD,
} from 'queries/basket';
import { getBasketId, removeCookie } from 'services/cookies';
import { GRAPH_QL_ERROR_TYPES } from 'constants/enums';
import { updateCachedBasket, useBasket } from './useBasket';
import { CREATE_PAYMENT_SETUP_INTENT, PAYMENT_METHODS, USER_STRIPE_BALANCE } from 'queries/payment';
import { formatPrice } from './../services/format';
import { useGlobalContext } from 'context/global/global-context';
import { getUtmParamsMap } from 'services/tracking';
import { COOKIES } from 'constants/cookies';
import { ERROR_MESSAGES } from 'constants/error-messages';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';
import { ApolloError } from 'apollo-client';

const useRemovePaymentIntent = (onComplete?: any) => {
  const [removePaymentIntent, { error, loading }] = useMutation(BASKET_REMOVE_PAYMENT_INTENT, {
    variables: { basketId: getBasketId(), cancelPaymentIntent: true },
    onCompleted: () => {
      onComplete && onComplete();
    },
    onError: error => {
      throw error;
    },
  });
  return { removePaymentIntent, error, loading };
};

const useSetBasketPaymentMethod = (
  onComplete?: any,
  updateCachedPaymentMethods: boolean = false
) => {
  const [setBasketPaymentMethod, { loading, error }] = useMutation(BASKET_SET_PAYMENT_METHOD, {
    update: (cache, response) => {
      const data: any = cache.readQuery({
        query: GET_BASKET,
        variables: { basketId: getBasketId() },
      });
      const newPayment = response?.data?.basket_setPaymentMethod;
      const updatedBasketData = {
        ...data.basket_getBasket,
        payment: newPayment,
      };

      updateCachedBasket(updatedBasketData);

      if (updateCachedPaymentMethods) {
        const paymentMethodsData: any = cache.readQuery({
          query: PAYMENT_METHODS,
        });

        const newPaymentMethodsData = {
          ...paymentMethodsData,
          user_paymentMethods: paymentMethodsData.user_paymentMethods.concat(newPayment),
        };

        cache.writeQuery({
          query: PAYMENT_METHODS,
          data: newPaymentMethodsData,
        });
      }
    },
    onCompleted: () => {
      onComplete && onComplete();
    },
    onError: error => {
      throw error;
    },
  });
  return { setBasketPaymentMethod, loading, error };
};

const usePaymentAddAndPay = (onComplete: any, onError?: (error: ApolloError) => void) => {
  const {
    orderBasket,
    error: errorOrderBasket,
    loading: loadingOrderBasket,
    paymentLabel,
  } = usePaymentPay(onComplete, onError);

  const [loadingStripe, setLoadingStripe] = useState(false);
  const [errorStripe, setErrorStripe] = useState('');

  const stripe = useStripe() as any;
  const elements = useElements() as any;

  const {
    setBasketPaymentMethod,
    loading: loadingSetBasketPaymentMethod,
    error: errorSetBasketPaymentMethod,
  } = useSetBasketPaymentMethod(orderBasket, true);

  const [createSetupIntent] = useMutation(CREATE_PAYMENT_SETUP_INTENT);

  const addPaymentMethod = async (formData: any) => {
    // if (!elements || !stripe) return;
    setLoadingStripe(true);

    const { error: errorCreatePaymentMethod, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement) as StripeCardElement,
      billing_details: {
        name: formData.name,
      },
    });

    if (errorCreatePaymentMethod) {
      setLoadingStripe(false);
      setErrorStripe(errorCreatePaymentMethod.message);
      return;
    }

    const { id } = paymentMethod;
    const createSetupIntentResponse = await createSetupIntent({
      variables: {
        token: id,
        setAsDefault: true,
      },
    });

    const { clientSecret } = createSetupIntentResponse.data.user_createPaymentSetupIntent;

    const { error: errorConfirmCardSetup, setupIntent } = await stripe.confirmCardSetup(
      clientSecret
    );

    if (errorConfirmCardSetup) {
      setLoadingStripe(false);
      setErrorStripe(errorConfirmCardSetup.message);
      return;
    }
    setLoadingStripe(false);

    if (setupIntent.status === 'succeeded') {
      try {
        setBasketPaymentMethod({
          variables: {
            basketId: getBasketId(),
            methodId: id,
          },
        });

        trackGAEvent('add_payment_info');
        trackHeap('add_payment_info');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const error = errorStripe || errorSetBasketPaymentMethod || errorOrderBasket;
  const loading = loadingStripe || loadingSetBasketPaymentMethod || loadingOrderBasket;

  return { loading, error, paymentLabel, addPaymentMethod };
};

const usePaymentSelection = (onComplete: any) => {
  const { data: dataBasket, loading: loadingBasket } = useBasket();

  const basketId = dataBasket?.basketId;
  const basketPayment = dataBasket?.payment;

  const {
    setBasketPaymentMethod,
    loading: loadingSetBasketPaymentMethod,
    error: errorSetBasketPaymentMethod,
  } = useSetBasketPaymentMethod(onComplete);

  const {
    loading: loadingPaymentMethods,
    error: errorPaymentMethods,
    data: dataPaymentMethods,
  } = useQuery(PAYMENT_METHODS);

  const setPaymentMethod = useCallback(
    (methodId: string) => {
      try {
        basketId &&
          setBasketPaymentMethod({
            variables: {
              methodId,
              basketId,
            },
          });
      } catch (error) {
        console.error(error);
      }
    },
    [basketId, setBasketPaymentMethod]
  );

  const basketPaymentId = basketPayment?.id;
  const paymentMethods = dataPaymentMethods?.user_paymentMethods;

  const preselectedPaymentMethod = useMemo(() => {
    if (basketPaymentId) {
      return basketPaymentId;
    }
    const defaultMethod = paymentMethods.find((method: any) => method.default);
    if (defaultMethod) {
      return defaultMethod.id;
    }
    if (paymentMethods.length === 1) {
      return paymentMethods[0].id;
    }
  }, [basketPaymentId, paymentMethods]);

  const data = dataPaymentMethods;
  const loading = loadingBasket || loadingPaymentMethods || loadingSetBasketPaymentMethod;
  const error = errorPaymentMethods || errorSetBasketPaymentMethod;

  return {
    data,
    loading,
    error,
    paymentMethods,
    basketPaymentId,
    setPaymentMethod,
    preselectedPaymentMethod,
  };
};

const usePaymentPay = (onComplete: any, onError?: (error: ApolloError) => void) => {
  const stripe = useStripe() as any;
  const { data: dataBasket, basketId, basketPayment, loading: loadingBasket } = useBasket();
  const { setIsLoadingPayment } = useGlobalContext();

  const [loadingSca, setloadingSca] = useState(false);
  const [errorSca, setErrorSca] = useState<string | null>(null);

  const paymentLabel =
    dataBasket && dataBasket?.totalWithCreditAllocated > 0
      ? `Pay  ${formatPrice(dataBasket.totalWithCreditAllocated, dataBasket.currency)}`
      : 'Get it free';

  const orderBasket = async () => {
    setIsLoadingPayment(true);
    await orderBasketMutation({
      variables: {
        basketId: getBasketId(),
        leadAttribution: getUtmParamsMap(),
      },
    });
  };

  const [orderBasketMutation, { data, error: errorOrder, loading: loadingOrder }] = useMutation(
    BASKET_ORDER,
    {
      variables: {
        basketId,
        leadAttribution: getUtmParamsMap(),
      },
      onCompleted(res) {
        if (res.basket_order.id) {
          trackGAEvent('checkout_payment_pay');
          trackHeap('checkout_payment_pay');
          removeCookie(COOKIES.basketId);
          onComplete(res.basket_order.id);
        }
      },
      onError(error) {
        setIsLoadingPayment(false);
        const errorRequiresSca: any = error.graphQLErrors.find(
          (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.PaymentRequiresSCA
        );

        if (errorRequiresSca) {
          const secret = errorRequiresSca.errorInfo?.secret;
          handleSca(secret);
        }
        onError && onError(error);
      },
    }
  );

  const authorisePayment = async (clientSecret: string, paymentIntent?: PaymentIntent) => {
    if (!paymentIntent) {
      return false;
    }

    if (!paymentIntent.next_action) {
      return true;
    }

    if (paymentIntent.next_action.type !== 'use_stripe_sdk') {
      // no need to do 3d secure
      return true;
    }

    if (
      paymentIntent.confirmation_method !== 'manual' ||
      paymentIntent.status === 'requires_payment_method'
    ) {
      const confirmPaymentData = await stripe.confirmCardPayment(clientSecret);
      if (
        confirmPaymentData.paymentIntent &&
        confirmPaymentData.paymentIntent.status !== 'requires_action'
      ) {
        return true;
      }
    }

    if (paymentIntent.status === 'requires_action') {
      const cardActionData = await stripe.handleCardAction(clientSecret);
      if (
        cardActionData.paymentIntent &&
        (cardActionData.paymentIntent.status === 'succeeded' ||
          cardActionData.paymentIntent.status === 'requires_confirmation')
      ) {
        return true;
      }
    }
  };

  const handleSca = async (secret: string) => {
    setloadingSca(true);
    setErrorSca(null);

    const clientSecrets = secret.split(',');
    let successfullPaymentIntentsCount = 0;

    for (let i = 0; i < clientSecrets.length; i++) {
      const clientSecret = clientSecrets[i];
      try {
        const paymentIntentData = (await stripe.retrievePaymentIntent(
          clientSecret
        )) as PaymentIntentResult;
        const paymentIntent = paymentIntentData?.paymentIntent;
        const basketPm = basketPayment?.id;
        const paymentIntentPm = paymentIntent?.payment_method;
        const isPmMatchingBasket = paymentIntentPm === basketPm;

        if (!isPmMatchingBasket) {
          continue;
        }

        const isAuthorisedPayment = await authorisePayment(clientSecret, paymentIntent);
        if (isAuthorisedPayment) {
          successfullPaymentIntentsCount++;
        }
      } catch (error) {
        console.error(error);
      }
    }

    setloadingSca(false);
    if (successfullPaymentIntentsCount === clientSecrets.length) {
      orderBasket();
      return;
    }
    // Some of the payment intents failed
    setErrorSca(ERROR_MESSAGES.defaultSCA);
    return;
  };

  const error = errorSca || errorOrder;
  const loading = loadingSca || loadingOrder || loadingBasket;

  return { data, error, loading, paymentLabel, orderBasket };
};

const useGetUserStripeBalance = () => {
  const { loading: loadingBalance, data, error: errorBalance } = useQuery(USER_STRIPE_BALANCE, {});
  return {
    loadingBalance,
    balance: data?.user_stripeBalance,
    errorBalance,
  };
};

export {
  useSetBasketPaymentMethod,
  useRemovePaymentIntent,
  usePaymentAddAndPay,
  usePaymentSelection,
  usePaymentPay,
  useGetUserStripeBalance,
};
