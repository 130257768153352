import { useMutation } from '@apollo/react-hooks';
import { COOKIES } from 'constants/cookies';
import { BASKET_MERGE_GUEST } from 'queries/basket';
import { removeCookie } from 'services/cookies';

export const useMergeGuestBasket = (finishCallback?: () => void) => {
  const [mergeGuestBasket, { data, loading, error }] = useMutation(
    BASKET_MERGE_GUEST,
    {
      onCompleted: () => {
        removeCookie(COOKIES.basketId);
        finishCallback && finishCallback();
      },
      onError: error => {
        throw error;
      },
    }
  );

  return {
    mergeGuestBasket,
    mergeBasket: {
      data,
      loading,
      error,
    },
  };
};
