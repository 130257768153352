import React from 'react';
import { CSSTransition } from 'react-transition-group';
import CustomInput from '../CustomInput';
import styles from './custom-address-selector.module.scss';
import fadeTransition from 'styles/fade.module.scss';

interface IProps {
  id: string;
  type?: string;
  label: string;
  register?: any;
  error?: any;
  errorMessage?: string;
  hasValue: boolean;
  disabled?: boolean;
  autocomplete?: string;
  onInput?: (value: any) => void;
  onClick?: any;
  customClass?: string;
  addressLookupMessage?: string;
  foundAddresses?: any;
}

const CustomAddressSelector: React.FC<IProps> = ({
  id,
  label,
  register,
  error,
  errorMessage,
  hasValue,
  onInput,
  onClick,
  customClass = '',
  addressLookupMessage,
  foundAddresses,
}) => {
  return (
    <div>
      <CustomInput
        {...{
          id,
          label,
          error,
          hasValue,
          errorMessage,
          register,
          autocomplete: 'off',
          onInput,
        }}
      />
      {addressLookupMessage !== '' && <span>{addressLookupMessage}</span>}
      <CSSTransition
        in={foundAddresses?.length > 0}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <div
          className={
            foundAddresses?.length > 3
              ? `${styles.addScroll} ${styles.addressList}`
              : `${styles.addressList}`
          }
        >
          {foundAddresses?.map((a: any, i: any) => (
            <p
              key={i}
              onClick={() => onClick(a)}
              className={a.Type !== 'Address' ? `${styles.addFlex}` : ''}
            >
              {a.Type !== 'Address' ? (
                <>
                  <span>
                    {a.Type === 'Postcode' ? `${a.Text}, ` : `${a.Text}`}
                    {a.Description.includes('-') && a.Description.split('-')[0]}
                    <span className={styles.addNewLine}>
                      {a.Description.includes('-')
                        ? a.Description.split('-')[1]
                        : a.Description}
                    </span>
                  </span>
                  <span className={styles.arrow}></span>
                </>
              ) : (
                <>
                  {a.Text},&nbsp;{a.Description}
                </>
              )}
            </p>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default CustomAddressSelector;
