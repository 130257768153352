import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { setToken } from 'services/auth';
import { getUtmParamsMap, trackEvent, trackIdentify } from 'services/tracking';
import { REGISTER_USER } from 'queries/user';
import { getCookie } from 'services/cookies';
import { COOKIES } from 'constants/cookies';
import { useMergeGuestBasket } from './useMergeGuestBasket';
import { trackHeapUserDetails } from 'services/tracking/heap';

export const useRegisterUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { mergeGuestBasket } = useMergeGuestBasket();

  const [registerUser] = useMutation(REGISTER_USER);

  const handleRegisterUser = async (name: string, email: string) => {
    setLoading(true);
    try {
      const registerRes: any = await registerUser({
        variables: {
          firstName: name,
          email: email,
          leadAttribution: getUtmParamsMap(),
        },
      });
      const token = registerRes?.data.user_register?.token;
      if (!token) return;

      setToken(registerRes.data.user_register.token);
      trackIdentify(`${registerRes.data.user_register.customerDataPlatformId}`);
      trackHeapUserDetails(email, name);
      trackEvent('Session Information', { is_logged_in: true });
      // trackReddit('SignUp', {
      //   currency: getCookie(COOKIES.currency),
      // });

      const basketId = getCookie(COOKIES.basketId);
      const isGuestBasketId = basketId && basketId.toString().length === 32;

      if (isGuestBasketId) {
        await mergeGuestBasket({ variables: { basketId } });
      }
      setLoading(false);
      return;
    } catch (e: any) {
      setLoading(false);
      setError(e);
      throw e;
    }
  };

  return {
    handleRegisterUser,
    loading,
    error,
  };
};
