import { isSSR } from "services/tracking";

export const identifyHeap = (id: string) => {
  if (!isSSR && window.heap) {
    window.heap.identify(id);
  }
}

export const trackHeapUserDetails = (email: string, name: string = '') => {
  if (!isSSR && window.heap) {
    window.heap.addUserProperties({ email, Name: name });
  }
};

export const trackHeap = (eventName: string, eventProps?: Object) => {
  if (!isSSR && window.heap) {
    window.heap.track(eventName, eventProps);
  }
};
