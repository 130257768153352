import React, { FC } from 'react';
import styles from './sms-opt-in-modal.module.scss'

interface ISmsOptInModalProps {
  onAccept: () => void;
  onDeny: () => void;
}

const SmsOptInModal:FC<ISmsOptInModalProps> = ({ onAccept, onDeny }) => {
  return (
    <div className={styles.smsOptInModal}>
      <h4>
        Do you want to set this as your default phone number?
      </h4>
      <p>
        Your default number is the one we’ll use to send you updates & offers via SMS.
      </p>
      <div className={styles.actionButtons}>
        <button className={styles.buttonPrimary} onClick={onAccept}>Yes</button>
        <button className={styles.buttonSecondary} onClick={onDeny}>No</button>
      </div>
    </div>
  );
};

export default SmsOptInModal;