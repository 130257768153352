import React from 'react';
import styles from './card-details.module.scss';
import SkeletonDefault from '../../core/skeletons/SkeletonDefault';
import Skeleton from 'react-loading-skeleton';
import SkeletonCard from '../../core/skeletons/SkeletonCard';

interface IProps {
  cardIssuer: string | undefined;
  lastFourDigits: string | undefined;
  loading: boolean;
}

const CardDetails: React.FC<IProps> = ({ cardIssuer, lastFourDigits, loading }) => {
  const CARD_IMAGES = require.context(`assets/img/cards`, true);

  if (loading) return <SkeletonCard />;

  if (!cardIssuer) return <span>Payment card is not selected</span>;

  return (
    <div className={styles.cardDetails}>
      <img src={CARD_IMAGES(`./${cardIssuer.toLowerCase()}.png`)} alt={cardIssuer} />
      <span>
        <strong>**** **** **** {lastFourDigits}</strong>
      </span>
    </div>
  );
};

export default CardDetails;
