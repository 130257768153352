import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { formatStringToBoolean } from './format';
import { USER_DETAILS } from 'queries/user';
import client from 'apollo/client';

export const initSentry = () => {
  const isSentryEnabled = formatStringToBoolean(
    process.env.REACT_APP_SENTRY_ENABLED
  );

  const getUserDetails = async () => {
    const { data: userDetailsData } = await client.query({
      query: USER_DETAILS,
    });
    return userDetailsData;
  };

  Sentry.init({
    beforeSend: event => {
      if (isSentryEnabled) return event;
      return null;
    },
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new Integrations.BrowserTracing()],
    sampleRate: 0.75,
    attachStacktrace: true,
    environment: `${process.env.REACT_APP_ENV}`,
  });

  Sentry.configureScope(async function (scope) {
    scope.setTransactionName(`Checkout`);
    const { user_details } = await getUserDetails();
    if (!user_details) return;
    scope.setUser({
      id: user_details.customerDataPlatformId,
    });
  });
};
