import { useQuery } from '@apollo/client';
import { GET_SHIPPING_ADDRESSES, STORE_COUNTRIES } from 'queries/delivery';
import { PAYMENT_METHODS } from 'queries/payment';
import { useEffect, useState } from 'react';
import { VIEWS } from 'views/Overview/constants';
import { useBasket } from './useBasket';

const useOverviewViews = () => {
  const [view, setView] = useState({
    deliveryView: VIEWS.delivery.default,
    paymentView: VIEWS.payment.default,
  });

  const setDeliveryView = (selectedView: string) => {
    setView(prevView => ({
      ...prevView,
      deliveryView: selectedView,
    }));
  };

  const setPaymentView = (selectedView: string) => {
    setView(prevView => ({
      ...prevView,
      paymentView: selectedView,
    }));
  };

  // queries
  const {
    basket,
    hasBasketPayment,
    loading: loadingBasket,
    hasBasketDeliveryAddress,
  } = useBasket();

  const {
    data: dataUserDeliveryAddresses,
    loading: loadingUserDeliveryAddresses,
  } = useQuery(GET_SHIPPING_ADDRESSES);

  const { loading: loadingCountries } = useQuery(STORE_COUNTRIES);

  const {
    loading: loadingPaymentMethods,
    data: dataPaymentMethods,
  } = useQuery(PAYMENT_METHODS, {});

  const userDeliveryAddresses =
    dataUserDeliveryAddresses?.user_deliveryAddresses;

  const loading =
    loadingBasket ||
    loadingUserDeliveryAddresses ||
    loadingCountries ||
    loadingPaymentMethods;

  useEffect(() => {
    if (basket && userDeliveryAddresses) {
      const hasUserDeliveryAddress = userDeliveryAddresses?.length > 0;
      const hasUserPaymentMethods =
        dataPaymentMethods?.user_paymentMethods.length > 0;

      if (!hasBasketDeliveryAddress) {
        if (hasUserDeliveryAddress) setDeliveryView(VIEWS.delivery.selection);
        else setDeliveryView(VIEWS.delivery.add);
      }

      if (!hasBasketPayment) {
        if (hasUserPaymentMethods) setPaymentView(VIEWS.payment.selection);
        else setPaymentView(VIEWS.payment.add);
      }

      if (!basket.deliveryAddressRequired) {
        setDeliveryView(VIEWS.delivery.hidden);
      }
    }
  }, [basket, userDeliveryAddresses, dataPaymentMethods]);

  return { view, setDeliveryView, setPaymentView, loading };
};

export { useOverviewViews };
