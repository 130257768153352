import React from 'react';
import { ApolloError } from 'apollo-client';
import styles from './error.module.scss';
import { formatGraphQLError } from 'services/format';

interface IProps {
  data: ApolloError | string | undefined;
  defaultStyles?: boolean;
}

const Error: React.FC<IProps> = ({ data, defaultStyles = true }) => {
  return (
    <>
      {data && <div className={defaultStyles ? styles.error : ''}>{formatGraphQLError(data)}</div>}
    </>
  );
};

export default Error;
