import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonDefault = () => {
  return (
    <div>
      <Skeleton width={`100%`} height={50} style={{ marginBottom: 8 }} />
      <Skeleton width={`100%`} height={50} style={{ marginBottom: 8 }} />
    </div>
  );
};
export default SkeletonDefault;
