import React from 'react';
import SkeletonBasket from './SkeletonBasket';
import SkeletonForm from './SkeletonForm';

import Layout from 'components/core/Layout';

const SkeletonApp = () => {
  return (
    <Layout
      bgColor={'white'}
      left={<SkeletonBasket />}
      right={<SkeletonForm />}
    />
  );
};
export default SkeletonApp;
