import React from 'react';
import { useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import { SkeletonForm } from 'components/core/skeletons';
import { ACCOUNT_INFO } from 'constants/account';
import { PAYMENT_METHODS } from 'queries/payment';
import { VIEWS } from '../constants';
import PaymentAddAndPay from './PaymentAddAndPay';
import PaymentPay from './PaymentPay';
import PaymentSelection from './PaymentSelection';
import { useBasket } from '../../../hooks/useBasket';

interface IProps {
  view: string;
  setView: any;
}

const Payment: React.FC<IProps> = ({ view, setView }) => {
  // queries
  const { loading: loadingPaymentMethods, data: dataPaymentMethods } = useQuery(
    PAYMENT_METHODS,
    {}
  );

  const { loading: loadingBasket } = useBasket();

  const onCompletePayment = (orderId: string) => {
    navigate(`${ACCOUNT_INFO.urls.url_success}&orderId=${orderId}`);
  };
  const loading = loadingPaymentMethods || loadingBasket;
  const hasPaymentMethods = dataPaymentMethods?.user_paymentMethods.length > 0;

  if (loading) {
    return <SkeletonForm />;
  }

  if (view === VIEWS.payment.selection) {
    return (
      <PaymentSelection
        onComplete={() => setView(VIEWS.payment.default)}
        onClose={() => setView(VIEWS.payment.default)}
        onAddNew={() => setView(VIEWS.payment.add)}
      />
    );
  }

  if (view === VIEWS.payment.add) {
    return (
      <PaymentAddAndPay
        {...{ onComplete: onCompletePayment }}
        onClose={() => setView(VIEWS.payment.default)}
      />
    );
  }

  return (
    <PaymentPay
      onChange={() => {
        hasPaymentMethods ? setView(VIEWS.payment.selection) : setView(VIEWS.payment.add);
      }}
      {...{ onComplete: onCompletePayment }}
    />
  );
};

export default Payment;
