import React from 'react';
import Button from 'components/core/Button';
import { SkeletonForm } from 'components/core/skeletons';
import { useGlobalContext } from 'context/global/global-context';
import { VIEWS } from './constants';
import Delivery from './Delivery';
import Payment from './Payment';
import { useOverviewViews } from 'hooks/useOverview';
import styles from './overview.module.scss';
import { useUserDetails } from '../../hooks/useUserDetails';
import { useBasket } from 'hooks/useBasket';
import { useGetUserStripeBalance } from 'hooks/usePayment';

const Overview: React.FC = () => {
  //queries
  const { userDetails, loading: loadingUserDetails } = useUserDetails();
  const { basket, hasBasketDeliveryAddress, loading: loadingBasket } = useBasket();
  const { balance, errorBalance } = useGetUserStripeBalance();

  const email = userDetails?.email;
  //context
  const { logOut, isLoadingPayment } = useGlobalContext();

  const {
    view: { deliveryView, paymentView },
    setDeliveryView,
    setPaymentView,
    loading: loadingOverview,
  } = useOverviewViews();

  const isDeliveryViewDefault = deliveryView === VIEWS.delivery.default;
  const isDeliveryViewHidden = !basket?.deliveryAddressRequired;
  const isPaymentViewDefault = paymentView === VIEWS.payment.default;
  const loading = loadingOverview || loadingUserDetails || loadingBasket;

  const renderDeliverySection = () => {
    if (isDeliveryViewHidden) {
      return null;
    }
    if (!isDeliveryViewDefault) {
      return <Delivery view={deliveryView} setView={setDeliveryView} />;
    }
    if (!hasBasketDeliveryAddress) {
      return <Delivery view={deliveryView} setView={setDeliveryView} />;
    }
    if (isDeliveryViewDefault && isPaymentViewDefault) {
      return <Delivery view={deliveryView} setView={setDeliveryView} />;
    }
    return null;
  };

  const renderPaymentSection = () => {
    if (isDeliveryViewHidden) {
      return <Payment view={paymentView} setView={setPaymentView} />;
    }
    if (!isDeliveryViewDefault || !hasBasketDeliveryAddress) {
      return null;
    }
    if (isPaymentViewDefault && isDeliveryViewDefault) {
      return <Payment view={paymentView} setView={setPaymentView} />;
    }
    if (isPaymentViewDefault && !isDeliveryViewDefault) {
      return null;
    }
    return <Payment view={paymentView} setView={setPaymentView} />;
  };

  if (loading) return <SkeletonForm />;

  return (
    <>
      <div className={styles.logoutWrapper}>
        {email && (
          <div className={styles.loggedInAs}>
            Logged in as: <span>{email}</span>
          </div>
        )}
        <Button type="link" label="Logout" onClick={logOut} disabled={isLoadingPayment} />
      </div>
      {/* This could be improved */}
      {renderDeliverySection()}
      {renderPaymentSection()}
    </>
  );
};

export default Overview;
