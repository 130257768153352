import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { COOKIES } from 'constants/cookies';
import { useGlobalContext } from 'context/global/global-context';
import { BASKET_MERGE_GUEST } from 'queries/basket';
import { MAGIC_SIGN_IN, USER_DETAILS } from 'queries/user';
import { useCallback, useState } from 'react';
import { removeToken, setToken } from 'services/auth';
import { getBasketId, getToken, removeCookie, setBasketId } from 'services/cookies';
import { trackEvent, trackIdentify } from 'services/tracking';
import { trackHeapUserDetails } from 'services/tracking/heap';

const useLogin = (onCompleteLogInFlow: () => void = () => undefined) => {
  const client = useApolloClient();

  const { isLoggedIn, logIn, logOut } = useGlobalContext();
  const [errorNeitherTokenNorBasketId, setErrorNeitherTokenNorBasketId] = useState<
    string | null
  >(null);

  const [
    mergeGuestBasket,
    { loading: loadingMergeGuestBasket, error: errorMergeGuestBasket },
  ] = useMutation(BASKET_MERGE_GUEST, {
    onCompleted: () => {
      removeCookie(COOKIES.basketId);
      logIn();
      onCompleteLogInFlow();
    },
  });

  const [
    exchangeTempToken,
    { loading: loadingExchangeTempToken, error: errorExchangeTempToken },
  ] = useMutation(MAGIC_SIGN_IN, {
    onCompleted: res => {
      const exchangedToken = res?.user_exchangeMagicLoginToken?.token;

      setToken(exchangedToken);
      client.resetStore();
    },
    onError: () => {
      removeToken();
    },
  });

  const [getUserDetails, { loading: loadingUserDetails }] = useLazyQuery(
    USER_DETAILS,
    {
      onCompleted(data) {
        const { user_details: userDetails } = data;
        const basketId = getBasketId();
        const isUserLoggedIn = userDetails;
        const isGuestBasketId = basketId && basketId.toString().length === 32;

        if (!isUserLoggedIn) return;

        trackIdentify(`${data.user_details.customerDataPlatformId}`);
        trackHeapUserDetails(userDetails.email, `${userDetails.firstName} ${userDetails.lastName}`);
        trackEvent('Session Information', { is_logged_in: true });

        if (isGuestBasketId) mergeGuestBasket({ variables: { basketId } });
        else {
          logIn();
          onCompleteLogInFlow();
        }
      },
      onError() {
        removeToken();
      },
    }
  );

  const logInFlow = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { token: tempToken, cartId: tempBasketId } = params;

    const exchangeToken = async () => {
      if (tempToken) {
        await exchangeTempToken({
          variables: { magicLinkToken: tempToken },
        });
      }
    };

    tempBasketId && setBasketId(tempBasketId);
    exchangeToken().then(() => {
      const token = getToken();
      const basketId = getBasketId();

      if (token) getUserDetails();
      else if (basketId) onCompleteLogInFlow();
      else
        setErrorNeitherTokenNorBasketId(
          'Please log in to your Vitl account or provide a valid basket-id'
        );
    });
  }, [getUserDetails, exchangeTempToken, onCompleteLogInFlow]);

  const error =
    errorExchangeTempToken || errorNeitherTokenNorBasketId || errorMergeGuestBasket;
  const loading =
    loadingExchangeTempToken || loadingUserDetails || loadingMergeGuestBasket;

  return { error, loading, isLoggedIn, logInFlow, logOut };
};

export { useLogin };
