export const ADDRESS_MANUAL_DATA = {
  inputs: [
    [
      {
        id: 'firstName',
        label: 'First name',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter your first name',
        },
      },
      {
        id: 'lastName',
        label: 'Last name',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter your last name',
        },
      },
    ],
    {
      id: 'street',
      label: 'Address',
      validation: { required: true },
      errorMessage: {
        required: 'Please enter the first line of your address',
      },
    },
    [
      {
        id: 'city',
        label: 'Town / City',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter your city',
        },
      },
      {
        id: 'postcode',
        label: 'Postcode',
        validation: { required: true },
        errorMessage: {
          required: 'Please enter a valid postcode',
        },
      },
    ],
  ],
  submitLabel: 'Continue',
};
