import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { SEND_MAGIC_LINK } from 'queries/user';
import DefaultForm from 'components/form/DefaultForm';
import { SIGN_IN_FORM_INFO } from './data';
import { ISignInForm, ISignInFormData } from 'interfaces/signin';
import { getBasketId } from 'services/cookies';
import EmailLoader from 'components/core/EmailLoader/EmailLoader';
import MagicLinkView from 'components/feature/MagicLinkView/MagicLinkView';
import { getUtmParamsAsUrlQueryParams } from 'services/tracking';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

interface ResetEmail {
  link: string;
  isVisible: boolean;
}

const ANIMATION_TIMEOUT = 7000;

const SignInForm: React.FC<ISignInForm> = ({ onCancel }) => {
  const basketId = getBasketId();
  const [sendMagicLink, { data, loading, error }] =
    useMutation(SEND_MAGIC_LINK);

  const [isSendingEmailAnimation, setIsSendingEmailAnimation] =
    useState<boolean>(false);
  const [email, setEmail] = useState<ResetEmail>({
    link: '',
    isVisible: false,
  });

  useEffect(() => {
    if (!isSendingEmailAnimation) {
      return;
    }

    const timer = setTimeout(function () {
      setIsSendingEmailAnimation(false);
    }, ANIMATION_TIMEOUT);

    return () => clearTimeout(timer);
  }, [isSendingEmailAnimation]);

  const onSubmit = (formData: ISignInFormData) => {
    // isEmpty(errors) && setUserEmail(formData.email);
    trackGAEvent('checkout_login');
    trackHeap('checkout_login');
    setIsSendingEmailAnimation(true);
    setEmail({ link: formData.email, isVisible: true });

    const destinationUrl = new URL(`${process.env.REACT_APP_HOST_NAME}`);

    const destinationUrlParams = {
      cartId: basketId,
      ...getUtmParamsAsUrlQueryParams(),
    };

    Object.entries(destinationUrlParams).forEach(([key, value]) => {
      if (value) {
        destinationUrl.searchParams.append(key, encodeURIComponent(value));
      }
    });

    // leadAttribution: getUtmParamsMap(),
    sendMagicLink({
      variables: {
        email: formData.email,
        destination: destinationUrl.toString(),
      },
    });
  };

  if (isSendingEmailAnimation) return <EmailLoader />;
  if (!isSendingEmailAnimation && email.isVisible) {
    return (
      <MagicLinkView
        message={SIGN_IN_FORM_INFO.successMessage}
        email={email.link}
        changeEmail={() => setEmail({ link: email.link, isVisible: false })}
      />
    );
  }

  const formInfo = {
    ...SIGN_IN_FORM_INFO,
    successMessage: `${SIGN_IN_FORM_INFO.successMessage} ${email.link}`,
    defaultValues: {
      email: email.link,
    },
  };

  return (
    <DefaultForm
      {...{
        formInfo,
        onSubmit,
        onCancel,
        data,
        loading,
        error,
      }}
    />
  );
};

export default SignInForm;
