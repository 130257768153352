import React from 'react';
import Button from '../Button';
import styles from './full-page-message.module.scss';

interface IProps {
  title?: string;
  message: any;
  onBack?: any;
}

const FullPageMessage: React.FC<IProps> = ({ title, message, onBack }) => {
  return (
    <div className={styles.fullPageMessage}>
      <div className={styles.container}>
        <p className={styles.title}>{title}</p>
        <div className={styles.message}>{message}</div>
        <Button type="link" label="Back" onClick={onBack} />
      </div>
    </div>
  );
};

export default FullPageMessage;
