import { COOKIES, COOKIE_EXPIRES_IN } from 'constants/cookies';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const domain = process.env.REACT_APP_COOKIES_DOMAIN;
const reactEnv = process.env.REACT_APP_ENV;
const isProduction = reactEnv === 'production';

const shouldAddEnvPrefix = (name: string) =>
  !isProduction && !name.startsWith('utm_');

const getEnvCookieName = (name: string) =>
  shouldAddEnvPrefix(name) ? `${reactEnv}_${name}` : name;

export const getCookie = (name: string): string => {
  return cookies.get(getEnvCookieName(name));
};

export const doesCookieExist = (name: string): boolean => {
  return getCookie(name) !== undefined;
};

export const setCookie = (name: string, value: string, maxAge: number) => {
  const cookieName = getEnvCookieName(name);
  removeCookie(cookieName);
  cookies.set(cookieName, value, { path: '/', maxAge: maxAge, domain });
};

export const removeCookie = (name: string) => {
  const cookieName = getEnvCookieName(name);
  cookies.remove(cookieName, { path: '/' });
  cookies.remove(cookieName, { path: '/', domain });
};

export const getToken = () => getCookie(COOKIES.token) || '';

export const setBasketId = (basketId: string) => {
  basketId && setCookie(COOKIES.basketId, basketId, COOKIE_EXPIRES_IN.thirtyDays);
};

export const getBasketId = () => getCookie(COOKIES.basketId) || null;

export const getCurrency = () => getCookie(COOKIES.currency) || 'GBP';
