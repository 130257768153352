import { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import { BASKET_CHECK_PRODUCT_RESTRICTIONS } from 'queries/basket';
import { getBasketId } from 'services/cookies';
import { useBasket } from './useBasket';
import { STORE_COUNTRIES } from 'queries/delivery';
import { getCountryNameById } from 'services/global';

const useRestrictedItems = () => {
  const { basketDeliveryAddress, loading: loadingBasket } = useBasket();

  const [
    basketCheckProductRestrictions,
    { data: dataProductRestrictions, loading: loadingProductRestrictions },
  ] = useLazyQuery(BASKET_CHECK_PRODUCT_RESTRICTIONS);

  useEffect(() => {
    const basketId = getBasketId();
    if (basketId) {
      basketCheckProductRestrictions({
        variables: { basketId },
      });
    }
  }, [basketDeliveryAddress, basketCheckProductRestrictions]);

  const { data: countriesData } = useQuery(STORE_COUNTRIES);

  const countries = countriesData?.store_countries;

  const restricted =
    dataProductRestrictions?.basket_checkProductRestrictions?.hasRestrictedItems &&
    dataProductRestrictions?.basket_checkProductRestrictions?.restrictedItems;

  const country =
    basketDeliveryAddress && getCountryNameById(countries, basketDeliveryAddress.countryId);

  const loading = loadingBasket || loadingProductRestrictions;

  return { restricted, country, loading };
};

export default useRestrictedItems;
