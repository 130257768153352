import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonProvider: React.FC = ({ children }) => {
  return (
    <SkeletonTheme baseColor="#e6e6e6a3" highlightColor="#ffffffbd">
      {children}
    </SkeletonTheme>
  );
};

export default SkeletonProvider;
