export const GUEST_FORM_INFO = {
  title: 'Personal details',
  subtitle: 'We use your details to personalise your experience',
  inputs: [
    {
      id: 'firstName',
      label: 'First name',
      validation: { required: true },
      errorMessage: {
        required: 'Please enter your first name',
      },
    },
    {
      id: 'email',
      type: 'email',
      label: 'Email address',
      validation: { required: true },
      errorMessage: {
        required: 'Please enter your email address',
      },
    },
  ],
  submitLabel: 'Continue',
  cancelLabel: 'Back',
};
