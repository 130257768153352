import { navigate } from '@reach/router';
import { ACCOUNT_INFO } from 'constants/account';
import { useCallback, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useAddShippingAddress, useEstimateShipping } from './useDelivery';
import { usePaymentPay, useRemovePaymentIntent, useSetBasketPaymentMethod } from './usePayment';
import { useRegisterUser } from './useRegisterUser';
import { useGlobalContext } from 'context/global/global-context';
import { isEmpty } from 'services/global';
import { useBasket } from './useBasket';
import { GRAPH_QL_ERROR_TYPES } from 'constants/enums';
import { ERROR_MESSAGES } from 'constants/error-messages';
import { IBasket } from '../interfaces/basket';
import { trackGAEvent } from 'services/tracking/ga';
import { trackHeap } from 'services/tracking/heap';

const useWallet = () => {
  //callbacks
  const onCompleteOrder = (orderId: string) => {
    navigate(`${ACCOUNT_INFO.urls.url_success}&orderId=${orderId}`);
  };

  const { basket, basketId, basketPayment, basketDeliveryAddress } = useBasket();

  //hooks
  const stripe = useStripe();
  const { handleRegisterUser } = useRegisterUser();

  const { orderBasket } = usePaymentPay(onCompleteOrder);
  const { addShippingAddress } = useAddShippingAddress();
  const { estimateShippingCosts } = useEstimateShipping();
  const { setBasketPaymentMethod } = useSetBasketPaymentMethod();
  const { removePaymentIntent } = useRemovePaymentIntent();

  //context
  const { isLoggedIn, featureFlags } = useGlobalContext();

  //state
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const [defaultDeliveryAddress, setDefaultDeliveryAddress] = useState<any>(null);

  //functions
  const formatAddress = (address: any) => {
    const recipientArray = address.recipient?.split(/\s+/);
    //Gets everything except the last word as the first name.
    //Example: recipient: John Christopher Depp
    //First Name: John Christopher
    //Last Name: Deep
    const hasOnlyFirstName = recipientArray.length === 1;
    const lastName = hasOnlyFirstName ? '' : recipientArray.pop();
    const firstName = recipientArray.join(' ');
    //Joins the array of addresses in a string with line breaks
    const street = address.addressLine?.join('\r\n');

    return {
      ...address,
      firstName,
      lastName,
      street,
    };
  };

  const formatPaymentRequestTotals = (basket: IBasket) => {
    return {
      total: {
        label: 'Total',
        amount: Math.floor(basket.totalWithCreditAllocated * 100),
      },
      shippingOptions: [
        {
          id: 'vitl',
          label: 'Vitl shipping method',
          detail: 'Standard',
          amount: basket.shipping * 100,
        },
      ],
      displayItems: [
        {
          amount: Math.floor(basket.subTotal * 100),
          label: `Subtotal`,
        },
        ...(basket.discountAmount
          ? [
              {
                amount: Math.floor(basket.discountAmount * 100),
                label: `Discount`,
              },
            ]
          : []),
        {
          amount: Math.floor(basket.shipping * 100),
          label: `Shipping`,
        },
      ],
    };
  };

  const updatePaymentRequest = (updatedBasket: any) => {
    if (!updatedBasket) return;
    paymentRequest?.update(formatPaymentRequestTotals(updatedBasket));
  };

  const estimateAndUpdateShippingCosts = async (
    shippingAddress: any,
    updateShippingCallback: any
  ) => {
    try {
      const estimatedShippingCostsData = await estimateShippingCosts({
        variables: {
          basketId,
          firstName: shippingAddress.firstName,
          lastName: shippingAddress.lastName,
          street: shippingAddress.street,
          city: shippingAddress.city,
          postcode: shippingAddress.postalCode,
          countryId: shippingAddress.country,
        },
      });
      const estimatedShippingCosts = estimatedShippingCostsData?.data?.basket_applyShippingAddress;

      updateShippingCallback({
        ...formatPaymentRequestTotals(estimatedShippingCosts),
        status: 'success',
      });
    } catch (error) {
      updateShippingCallback({ status: 'fail' });
      return;
    }
  };

  const onSubmitWallet = async (paymentMethod: any, shippingAddress: any) => {
    const paymentMethodId = paymentMethod.id;
    const hasShippingAddress = shippingAddress && shippingAddress.street;
    const basketPm = basketPayment?.id;
    const isPmMatchingBasket = paymentMethodId === basketPm;

    if (hasShippingAddress) {
      await addShippingAddress({
        variables: {
          basketId: null,
          firstName: shippingAddress.firstName,
          lastName: shippingAddress.lastName,
          street: shippingAddress.street,
          city: shippingAddress.city,
          postcode: shippingAddress.postalCode,
          countryId: shippingAddress.country,
        },
      });
    }
    await setBasketPaymentMethod({
      variables: {
        basketId: null,
        methodId: paymentMethodId,
      },
    });
    if (!isPmMatchingBasket) await removePaymentIntent();
    await orderBasket();
  };

  const resetShippingAddress = async () => {
    if (!defaultDeliveryAddress) return;
    setLoading(true);
    await addShippingAddress({
      variables: {
        basketId,
        firstName: defaultDeliveryAddress.firstName,
        lastName: defaultDeliveryAddress.lastName,
        street: defaultDeliveryAddress.street,
        city: defaultDeliveryAddress.city,
        postcode: defaultDeliveryAddress.postcode,
        countryId: defaultDeliveryAddress.countryId,
      },
    });
    setLoading(false);
  };
  // Listeners
  const addListeners = useCallback(() => {
    // Event triggered onSubmit
    paymentRequest?.on('paymentmethod', async (ev: any) => {
      const { payerName, payerEmail, paymentMethod, shippingAddress, complete } = ev;
      setError(null);
      
      if(ev.walletName === 'applePay') {
        trackGAEvent('selected_applepay_checkout');
        trackHeap('selected_applepay_checkout');
      } else if(ev.walletName === 'googlePay') {
        trackGAEvent('selected_googlepay_checkout');
        trackHeap('selected_googlepay_checkout');
      }

      try {
        if (!isLoggedIn) {
          try {
            await handleRegisterUser(payerName, payerEmail);
          } catch (err: any) {
            const error = err?.graphQLErrors[0];
            const errorType = error.errorType as GRAPH_QL_ERROR_TYPES;
            const isAbortAvailable = Boolean(paymentRequest.abort);

            if (errorType === GRAPH_QL_ERROR_TYPES.EmailExists) {
              isAbortAvailable ? paymentRequest.abort() : complete('invalid_payer_email');
              setError(ERROR_MESSAGES.emailExists);
              return;
            }

            complete('fail');
            console.log(err, 'error log');
            setError(err);
            // setErrorModalMessage(err?.graphQLErrors[0].message);

            return;
          }
        }
        await onSubmitWallet(paymentMethod, formatAddress(shippingAddress));
        if(ev.walletName === 'applePay') {
          trackGAEvent('order_completed_applepay_checkout');
          trackHeap('order_completed_applepay_checkout');
        } else if(ev.walletName === 'googlePay') {
          trackGAEvent('order_completed_googlepay_checkout');
          trackHeap('order_completed_googlepay_checkout');
        }
        complete('success');
      } catch (err: any) {
        console.log(err, 'error on app');
        complete('fail');
        setError(err);
      }
    });

    // Event triggered on shippingaddresschange and when Apple Pay modal is shown
    paymentRequest?.on('shippingaddresschange', (ev: any) => {
      const updateWith = ev.updateWith;
      const shippingAddress = formatAddress(ev.shippingAddress);

      if (!shippingAddress) {
        updateWith({ status: 'fail' });
        return;
      }
      estimateAndUpdateShippingCosts(shippingAddress, updateWith);
    });

    paymentRequest?.on('cancel', async () => resetShippingAddress());
  }, [paymentRequest]);

  //Saves the payment request if Apple Pay or Google Pay is available
  const savePrIfAvailable = useCallback(async () => {
    if (!stripe || !basket || isEmpty(basket)) return;
    setError(null);
    setLoading(true);
    try {
      const pr: any = stripe.paymentRequest({
        ...formatPaymentRequestTotals(basket),
        country: 'GB',
        currency: basket?.currency.toLowerCase(),
        requestPayerName: true,
        requestPayerEmail: !isLoggedIn,
        requestShipping: true,
      });
      const result = await pr.canMakePayment();
      const isApplePayAvailable = result?.applePay && featureFlags?.apple;
      const isGooglePayAvailable = result?.googlePay && featureFlags?.google;

      if (isApplePayAvailable || isGooglePayAvailable) setPaymentRequest(pr);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setLoading(false);
    }
  }, [stripe, basket, isLoggedIn]);

  useEffect(() => {
    //Address with the street populated will be saved as the default address one. Later on, it will be used onCancel Apple Pay modal to reset it back.
    const isDeliveryAddressPartial =
      !basketDeliveryAddress?.street || basketDeliveryAddress?.street === '-';

    if (!isDeliveryAddressPartial) {
      setDefaultDeliveryAddress(basketDeliveryAddress);
    }
  }, [basketDeliveryAddress]);

  useEffect(() => {
    const areFeatureFlagsActive = featureFlags?.apple || featureFlags?.google;

    if (!areFeatureFlagsActive) return;
    savePrIfAvailable();
  }, [savePrIfAvailable, featureFlags, isLoggedIn]);

  // add listeners on mounting
  useEffect(() => {
    const cleanListeners = () => {
      paymentRequest?.removeAllListeners();
    };

    cleanListeners();
    addListeners();
  }, [addListeners]);

  return {
    paymentRequest,
    loading,
    error,
    updatePaymentRequest,
  };
};

export { useWallet };
