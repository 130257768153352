const reactSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: state.isFocused ? '#333333' : '#919191',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '0',
    height: '54px',
    marginBottom: '32px',
    paddingLeft: '16px',
    paddingTop: '16px',
    fontSize: '16px',
    color: '#222222',
    fontFamily: "'sofia-pro', sans-serif",
    '&:hover': {
      borderColor: state.isFocused ? '#333333' : '#919191',
    },
  }),
  container: (baseStyles: any) => ({
    ...baseStyles,
    paddingLeft: '0',
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    paddingLeft: '0',
    marginLeft: '0',
    left: '-1px',
  }),
  singleValue: (baseStyles: any) => ({
    ...baseStyles,
    marginLeft: '0',
    color: '#222222',
  }),
  input: (baseStyles: any) => ({
    ...baseStyles,
    marginLeft: '0',
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? '#2b2b2b' : '#ffffff',
    color: state.isSelected ? '#ffffff' : '#222222',
    transition: 'all 0.1s ease 0ms',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2b2b2b' : '#f9f8f4',
      color: state.isSelected ? '#ffffff' : '#222222',
    },
  }),
};

export default reactSelectStyles;
